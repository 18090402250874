import React, { Fragment, useState, useEffect } from "react";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { backend, API_URL } from "../backend_api";
import { OneOffPayout } from "./OneOffPayout";
import { OneOffReview } from "./OneOffReview";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      width: 900,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const OneOffProcess = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["One-Off Payouts", "Review"];

  const [confirmModal, setConfirmModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [finishedFlag, setFinishedFlag] = useState(false);
  const [partners, setPartners] = useState([]);
  const [partnersRemapped, setPartnersRemapped] = useState(false);
  const [partnersEntities, setPartnersEntities] = useState([]);
  const [entities, setEntities] = useState([]);
  const [entitiesFull, setEntitiesFull] = useState([]);
  const [oneOffPayouts, setOneOffPayouts] = useState([]);
  const [extraOneOffPayout, setExtraOneOffPayout] = useState({
    partner: "",
    entity: "",
    amount: "",
    description: "",
    payin: false,
    sendStatement: false,
  });
  const [payinAll, setPayinAll] = useState(false);
  const [sendStatementAll, setSendStatementAll] = useState(false);

  const handleCancel = () => {
    props.history.push("/oneoffs");
  };

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      toggleConfirmModal();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleOneOffPayoutsChange = (idx) => (e) => {
    let name = null;
    let value = null;
    name = e.target.name.replace("-" + idx, "");
    value =
      name.includes("payin") || name.includes("sendStatement")
        ? e.target.checked
        : e.target.value;

    setOneOffPayouts((prevState) => {
      return prevState.map((item, index) => {
        if (idx !== index) return item;
        return name.includes("partner")
          ? { ...item, [name]: value, entity: "" }
          : { ...item, [name]: value };
      });
    });
  };

  const handleExtraOneOffPayoutChange = (e) => {
    const { name, value, checked } = e.target;
    setExtraOneOffPayout((prevState) => {
      if (name.includes("partner")) {
        let partnerObj = partners.find((partner) => partner.pk === value);
        setEntities(
          partnerObj
            ? entitiesFull.filter((entity) =>
                partnerObj.entities.includes(entity.pk)
              )
            : entitiesFull
        );
      }
      return {
        ...prevState,
        [name]:
          name.includes("payin") || name.includes("sendStatement")
            ? checked
            : value,
      };
    });
  };

  const handleExtraOneOffPayoutAddChange = () => {
    setOneOffPayouts((prevState) => [...prevState, extraOneOffPayout]);
    setEntities(entitiesFull);
    setExtraOneOffPayout({
      partner: "",
      entity: "",
      amount: "",
      description: "",
      payin: false,
      sendStatement: false,
    });
  };

  const handlePayinAllChange = (e) => {
    const { checked } = e.target;

    setOneOffPayouts((prevState) =>
      prevState.map((item) => ({ ...item, payin: checked }))
    );

    setPayinAll(checked);
  };

  const handleSendStatementAllChange = (e) => {
    const { checked } = e.target;

    setOneOffPayouts((prevState) =>
      prevState.map((item) => ({ ...item, sendStatement: checked }))
    );

    setSendStatementAll(checked);
  };

  useEffect(() => {
    const getPartners = async () => {
      await backend
        .get(`${API_URL}data/api/partners?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => {
            return {
              pk: item.pk,
              name: item.name,
            };
          });
          setPartners(dataMap);
        });
    };

    const getEntities = async () => {
      await backend
        .get(`${API_URL}data/api/entities?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => ({
            pk: item.pk,
            name: item.name,
          }));
          setEntities(dataMap);
          setEntitiesFull(dataMap);
        });
    };

    const getPartnersEntities = async () => {
      await backend
        .get(`${API_URL}data/api/partnerentitythroughs?no_page=1`)
        .then(({ data }) => {
          setPartnersEntities(data);
        });
    };

    getPartners();
    getEntities();
    getPartnersEntities();
  }, []);

  useEffect(() => {
    if (
      partners.length &&
      entities.length &&
      partnersEntities.length &&
      !partnersRemapped
    ) {
      let partnersNewDict = partners.map((item) => ({
        ...item,
        entities: partnersEntities
          .filter((peItem) => peItem.partner_id === item.pk)
          .map((peItem) => peItem.entity_id),
      }));
      setPartners(partnersNewDict);
      setPartnersRemapped(true);
    }
  }, [partners, entities, partnersEntities, partnersRemapped]);

  useEffect(() => {
    setPayinAll(oneOffPayouts.every((item) => item.payin));
    setSendStatementAll(oneOffPayouts.every((item) => item.sendStatement));
  }, [oneOffPayouts]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <OneOffPayout
            {...props}
            partners={partners}
            entities={entities}
            entitiesFull={entitiesFull}
            oneOffPayouts={oneOffPayouts}
            extraOneOffPayout={extraOneOffPayout}
            payinAll={payinAll}
            sendStatementAll={sendStatementAll}
            handleOneOffPayoutsChange={handleOneOffPayoutsChange}
            handleExtraOneOffPayoutChange={handleExtraOneOffPayoutChange}
            handleExtraOneOffPayoutAddChange={handleExtraOneOffPayoutAddChange}
            handlePayinAllChange={handlePayinAllChange}
            handleSendStatementAllChange={handleSendStatementAllChange}
          />
        );
      case 1:
        return (
          <OneOffReview
            {...props}
            partners={partners}
            oneOffPayouts={oneOffPayouts}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      {!loadingFlag && !finishedFlag ? (
        <>
          Are you sure you want to send the payouts to <b>Stripe</b>
          <div align="right">
            <Button
              onClick={() => toggleConfirmModal()}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => sendRequest()}
              className={classes.button}
            >
              Confirm
            </Button>
          </div>
        </>
      ) : loadingFlag ? (
        <>
          <div align="center">
            <CircularProgress />
            <div>
              Sending Payouts <br />
              This may take some time relative to the number of payouts.
            </div>
          </div>
          {errorInfo.error ? (
            <>
              <br />
              <br />
              There was an error while making the payouts
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  wordBreak: "break-word",
                  maxHeight: 160,
                  overflow: "hidden",
                  display: "inline-block",
                }}
              >
                <b>Error:</b>
                <br />
                {errorInfo.message}
              </span>
              <div align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => resolveRequest()}
                  className={classes.button}
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          Payouts sent
          <div align="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => resolveRequest()}
              className={classes.button}
            >
              Close
            </Button>
          </div>
        </>
      )}
    </div>
  );

  const resolveRequest = () => {
    setFinishedFlag(false);
    toggleConfirmModal();
    props.history.push("/payout");
  };

  const sendRequest = () => {
    setLoadingFlag(true);
    let oneOffPayin = [];
    let oneOffNonPayin = [];
    let oneOffPayinSS = false;
    let oneOffNonPayinSS = false;
    let payoutDate = new Date().toISOString();

    oneOffPayouts.forEach((item) => {
      let oneOffObj = {
        partner: item.partner,
        entity: item.entity,
        raas_fees: item.amount,
        description: item.description,
        payout_date: payoutDate,
      };

      item.payin ? oneOffPayin.push(oneOffObj) : oneOffNonPayin.push(oneOffObj);
      oneOffPayinSS = !oneOffPayinSS ? item.sendStatement : false;
      oneOffNonPayinSS = !oneOffNonPayinSS ? item.sendStatement : false;
    });

    if (oneOffPayin.length > 0) {
      oneOffRequest(oneOffPayouts, oneOffPayin, oneOffPayinSS, true);
    }
    if (oneOffNonPayin.length > 0) {
      oneOffRequest(oneOffPayouts, oneOffNonPayin, oneOffNonPayinSS, false);
    }
  };

  const oneOffRequest = async (
    oneOffPayouts,
    payload,
    sendStatements,
    payin = false
  ) => {
    let payinParam = payin ? "?pay_in=" + payin : "";
    await backend
      .post(`${API_URL}data/api/nonorderpayouts/${payinParam}`, payload)
      .then((response) => {
        let payoutPks = response.data.length ? response.data : [];
        let payoutPksSS = {
          payout_pk_list: payoutPks
            .filter((item, index) => oneOffPayouts[index].sendStatement)
            .map((item) => item.pk),
        };
        setLoadingFlag(false);
        if (sendStatements) {
          sendStatementsRequest(payoutPksSS);
        }
        setFinishedFlag(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
      });
  };

  const sendStatementsRequest = async (payoutPks) => {
    await backend
      .post(
        `${API_URL}data/api/generatestatements/?email_out=true&payout_type=nonorder`,
        payoutPks
      )
      .then((response) => {
        setLoadingFlag(false);
        setFinishedFlag(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
      });
  };

  return (
    <div>
      <Fragment>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              One-Off Payout
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Modal open={confirmModal} onClose={toggleConfirmModal}>
              {confirmModalContent}
            </Modal>
            <Fragment>
              {activeStep === steps.length ? (
                <Fragment></Fragment>
              ) : (
                <Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </Paper>
        </main>
      </Fragment>
    </div>
  );
};
