import React, { Fragment } from "react";
import { Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import InfiniteTable from "./InfiniteTable";

const columns = [
  {
    id: "raas_location_id",
    editable: "/partners",
    editablePk: "pk",
    label: "Location ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "external_name",
    label: "External name",
  },
  { id: "address", label: "Address" },
  {
    id: "primary_email",
    label: "Email",
  },
  {
    id: "stripe",
    label: "Stripe",
  },
];

export const Partners = () => {
  return (
    <>
      <Container style={{ textAlign: "right" }}>
        <Link
          to="/partners/add"
          style={{ textDecoration: "none" }}
          color="secondary"
        >
          <Button variant="contained" color="primary">
            <Typography variant="subtitle2" className="text-white">
              Add Partner
            </Typography>
          </Button>
        </Link>
      </Container>
      <Container>
        <InfiniteTable
          apiep="data/api/partners"
          columns={columns}
          data={null}
          dataMapByColumn="id"
          deletable={false}
          editable={true}
          filterable={true}
          label="Partners"
          fetchingModal={null}
        />
      </Container>
    </>
  );
};
