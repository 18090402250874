import React, { Fragment } from "react";
import {
  Typography,
  Container,
  TextField,
  CssBaseline,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  iconButton: {
    padding: 10,
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const EntityForm = (props) => {
  const classes = useStyles();
  const { handleChange, entity} = props;

  return (
    <Fragment>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography color="primary" component="h4">
            Entity Information
          </Typography>
          <form
            className={classes.form}
            margin="dense"
            noValidate
            autoComplete="off"
          >
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                size="small"
                required={true}
                variant="outlined"
                label="Name: "
                name="name"
                id="name"
                value={entity && entity.name}
                onChange={handleChange}
              />
            </Grid>
          </form>
        </div>
      </Container>
    </Fragment>
  );
};

export default EntityForm;
