import "./App.css";
import React from "react";
import { AuthProvider } from "./auth/authcontext";
import { ThemeProvider } from "@mui/material";

import CustomTheme from "./CustomTheme";
import Splash from "./components/splash";

import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <ThemeProvider theme={CustomTheme}>
      <AuthProvider>
        <Router>
          <Splash />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
