import { createTheme } from "@mui/material/styles";

const CustomTheme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 12,
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#2a4158",
    },
    secondary: {
      main: "#8c9ea3",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    body2: {
      marginBottom: 0,
    },
  },
});

export default CustomTheme;
