import React from "react";
import InfiniteTable from "./InfiniteTable";

export const DashboardOrders = () => {
  const columns = [
    { id: "oid", editable: "/orders", editablePk: "pk", label: "Order ID" },
    { id: "order_date", label: "Order date" },
    {
      id: "partner_name",
      editable: "/partners",
      editablePk: "partner",
      label: "Partner",
    },
    { id: "sales", label: "Sales" },
    { id: "stated_payout", label: "Stated payout" },
    { id: "stated_payout_date", label: "Stated payout date" },
    {
      id: "delivery_company_name",
      editable: "/deliverycompanies",
      editablePk: "delivery_company",
      label: "Delivery company",
    },
  ];

  return (
    <>
      <InfiniteTable
        apiep="data/api/orders"
        columns={columns}
        data={null}
        dataMapByColumn="id"
        deletable={false}
        editable={true}
        filterable={true}
        label="Recent Orders"
        fetchingModal={null}
      />
    </>
  );
};

export default DashboardOrders;
