import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Input
} from "@mui/material";
import { backend, API_URL } from "../backend_api";
import InfiniteTable from "./InfiniteTable";

export const Search = () => {
  const [currentSearchCriteria, setCurrentSearchCriteria] = useState({
    object: "partner",
    field: "",
    action: "exact",
    query: "",
  });
  const [data, setData] = useState([]);
  const [fields, setFields] = useState({
    partner: [{ id: "name", label: "Name" }],
  });
  const [hideInfiniteTable, setHideInfiniteTable] = useState(true);
  const [searchCriteria, setSearchCriteria] = useState({
    object: "partner",
    field: "",
    action: "exact",
    query: "",
  });
  /* const [confirmModal, setConfirmModal] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" }); */

  const handleCriteriaSearch = (e) => {
    setSearchCriteria((prevState) => {
      const { name, value } = e.target;
      let criteria = prevState;
      if (name === "object") {
        criteria.field = "";
      }
      return {
        ...criteria,
        [name]: value,
      };
    });
  };

  const handleSearch = () => {
    if (currentSearchCriteria.object !== searchCriteria.object) {
      setHideInfiniteTable(true);
    }
    setCurrentSearchCriteria(searchCriteria);
    const objectGet = async () => {
      let objectParam =
        searchCriteria.object !== "" ? "object=" + searchCriteria.object : "";
      let fieldParam =
        searchCriteria.field !== "" ? "&field=" + searchCriteria.field : "";
      let actionParam =
        searchCriteria.action !== "" ? "&action=" + searchCriteria.action : "";
      let queryParam =
        searchCriteria.query !== "" ? "&query=" + searchCriteria.query : "";

      await backend
        .get(
          `${API_URL}data/api/objectsearch?${objectParam}${fieldParam}${actionParam}${queryParam}`
        )
        .then(({ data }) => {
          setData(data);
          setHideInfiniteTable(false);
        });
    };
    if (
      JSON.stringify(currentSearchCriteria) !==
        JSON.stringify(searchCriteria) ||
      !data.length
    ) {
      objectGet();
    }
  };

  useEffect(() => {
    const getFields = async () => {
      await backend
        .get(`${API_URL}data/api/objectsearchfields?no_page=1`)
        .then(({ data }) => {
          let fieldsDict = {};
          Object.keys(data).forEach((object) => {
            let fieldsMap = data[object]
              .filter((item) => item !== "id")
              .map((item) => ({
                id: item,
                label:
                  item[0].toUpperCase() +
                  item.slice(1).replace(new RegExp("_", "g"), " "),
              }));
            fieldsDict[object] = fieldsMap;
          });
          setFields(fieldsDict);
        });
    };

    getFields();
  }, []);

  return (
    <>
      <form margin="dense" noValidate autoComplete="off">
        <Container>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ width: 150, marginRight: 10 }}>
              <InputLabel shrink id="objectLabel">
                Object
              </InputLabel>
              <Select
                fullWidth
                key="select"
                name="object"
                label="Object"
                input={<Input />}
                value={searchCriteria && searchCriteria.object}
                onChange={handleCriteriaSearch}
              >
                <MenuItem key="partner" value="partner">
                  Partner
                </MenuItem>
                <MenuItem key="delivery_company" value="delivery_company">
                  Delivery Company
                </MenuItem>
                <MenuItem
                  key="stripe_configuration"
                  value="stripe_configuration"
                >
                  Stripe
                </MenuItem>
                <MenuItem key="fee" value="fee">
                  Fee
                </MenuItem>
                <MenuItem key="brand" value="brand">
                  Brand
                </MenuItem>
                <MenuItem key="entity" value="entity">
                  Entity
                </MenuItem>
                <MenuItem key="payouts" value="payout">
                  Payouts
                </MenuItem>
                <MenuItem key="order" value="order">
                  Order
                </MenuItem>
              </Select>
            </div>
            <div style={{ width: 150, marginRight: 10 }}>
              <InputLabel shrink id="fieldLabel">
                Field
              </InputLabel>
              <Select
                fullWidth
                key="select"
                name="field"
                label="Field"
                input={<Input />}
                value={searchCriteria && searchCriteria.field}
                onChange={handleCriteriaSearch}
              >
                <MenuItem value="">
                  <em>- All -</em>
                </MenuItem>
                {fields[searchCriteria.object].map((field, index) => (
                  <MenuItem key={"field-" + index} value={field.id}>
                    {field.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div style={{ width: 150, marginRight: 10 }}>
              <InputLabel shrink id="actionLabel">
                Action
              </InputLabel>
              <Select
                fullWidth
                key="select"
                name="action"
                label="Action"
                input={<Input />}
                value={searchCriteria && searchCriteria.action}
                onChange={handleCriteriaSearch}
              >
                <MenuItem key="exact" value="exact">
                  Equals (case sensitive)
                </MenuItem>
                <MenuItem key="iexact" value="iexact">
                  Equals
                </MenuItem>
                <MenuItem key="contains" value="contains">
                  Contains (case sensitive)
                </MenuItem>
                <MenuItem key="icontains" value="icontains">
                  Contains
                </MenuItem>
                <MenuItem key="in" value="in">
                  In
                </MenuItem>
                <MenuItem key="gt" value="gt">
                  Greater than
                </MenuItem>
                <MenuItem key="gte" value="gte">
                  Greater than or equal
                </MenuItem>
                <MenuItem key="lt" value="lt">
                  Less than
                </MenuItem>
                <MenuItem key="lte" value="lte">
                  Less than or equal
                </MenuItem>
                <MenuItem key="startswith" value="startswith">
                  Starts with (case sensitive)
                </MenuItem>
                <MenuItem key="istartswith" value="istartswith">
                  Starts with
                </MenuItem>
                <MenuItem key="endswith" value="endswith">
                  Ends with (case sensitive)
                </MenuItem>
                <MenuItem key="iendswith" value="iendswith">
                  Ends with
                </MenuItem>
                <MenuItem key="range" value="range">
                  Range
                </MenuItem>
                <MenuItem key="date" value="date">
                  Date
                </MenuItem>
                <MenuItem key="year" value="year">
                  Year
                </MenuItem>
                <MenuItem key="iso_year" value="iso_year">
                  ISO year
                </MenuItem>
                <MenuItem key="month" value="month">
                  Month
                </MenuItem>
                <MenuItem key="day" value="day">
                  Day
                </MenuItem>
                <MenuItem key="week" value="week">
                  Week
                </MenuItem>
                <MenuItem key="week_day" value="week_day">
                  Week day
                </MenuItem>
                <MenuItem key="iso_week_day" value="iso_week_day">
                  ISO week day
                </MenuItem>
                <MenuItem key="quarter" value="quarter">
                  Quarter
                </MenuItem>
                <MenuItem key="time" value="time">
                  Time
                </MenuItem>
                <MenuItem key="hour" value="hour">
                  Hour
                </MenuItem>
                <MenuItem key="minute" value="minute">
                  Minute
                </MenuItem>
                <MenuItem key="second" value="second">
                  Second
                </MenuItem>
                <MenuItem key="isnull" value="isnull">
                  Is NULL
                </MenuItem>
                <MenuItem key="regex" value="regex">
                  Regex (case sensitive)
                </MenuItem>
                <MenuItem key="iregex" value="iregex">
                  Regex
                </MenuItem>
              </Select>
            </div>
            <div style={{ flexGrow: 1, marginRight: 10 }}>
              <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="Search for"
                name="query"
                value={searchCriteria && searchCriteria.query}
                onChange={handleCriteriaSearch}
              />
            </div>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              <Typography variant="subtitle2" className="text-white">
                Search
              </Typography>
            </Button>
          </div>
        </Container>
        <Container>
          {hideInfiniteTable ? (
            <></>
          ) : (
            <InfiniteTable
              apiep={null}
              columns={fields[currentSearchCriteria.object]}
              data={data}
              dataMapByColumn="id"
              deletable={false}
              editable={true}
              filterable={true}
              label={
                currentSearchCriteria.object.charAt(0).toUpperCase() +
                currentSearchCriteria.object.slice(1).replace("_", " ")
              }
              fetchingModal={null}
            />
          )}
        </Container>
      </form>
    </>
  );
};
