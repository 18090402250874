import React, { useState } from "react";

import {
  Typography,
  Container,
  TextField,
  useTheme,
  CssBaseline,
  Grid,
  Select,
  Chip,
  Input,
  MenuItem,
  Checkbox,
  InputLabel,
  FormControlLabel,
  Button,
  CircularProgress,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { backend, API_URL } from "../backend_api";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 385,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const getStyles = (name, partnerSelected, theme) => {
  return {
    fontWeight:
      partnerSelected.delivery_companies.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const PartnerForm = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    handleChange,
    partnerSelected,
    entities,
    brands,
    deliveryCompanies,
    stripe,
  } = props;

  const [confirmOnboardModal, setConfirmOnboardModal] = useState(false);
  const toggleConfirmOnboardModal = () =>
    setConfirmOnboardModal(!confirmOnboardModal);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [finishedFlag, setFinishedFlag] = useState(false);

  const confirmOnboardModalContent = (
    <div className={classes.modal}>
      {!loadingFlag && !finishedFlag ? (
        <>
          Are you sure you want to reset <b>onboard link</b>?
          <div align="right">
            <Button
              onClick={() => toggleConfirmOnboardModal()}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => sendOnboardRequest()}
              className={classes.button}
            >
              Confirm
            </Button>
          </div>
        </>
      ) : loadingFlag ? (
        <>
          <div align="center">
            <CircularProgress />
            <div>Refreshing...</div>
          </div>
          {errorInfo.error ? (
            <>
              <br />
              <br />
              There was an error while refreshing onboard link
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  wordBreak: "break-word",
                  maxHeight: 160,
                  overflow: "hidden",
                  display: "inline-block",
                }}
              >
                <b>Error:</b>
                <br />
                {errorInfo.message}
              </span>
              <div align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => resolveRequest()}
                  className={classes.button}
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          Onboard link has been refreshed successfully
          <div align="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => resolveRequest()}
              className={classes.button}
            >
              Close
            </Button>
          </div>
        </>
      )}
    </div>
  );

  const resolveRequest = () => {
    toggleConfirmOnboardModal();
    setFinishedFlag(false);
    props.history.push("/partners");
  };

  const sendOnboardRequest = async () => {
    setLoadingFlag(true);
    await backend
      .put(`${API_URL}data/api/stripegeneratelink/`, null, {
        params: {
          account_id: partnerSelected.stripe,
        },
      })
      .then((response) => {
        setLoadingFlag(false);
        setFinishedFlag(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Modal open={confirmOnboardModal} onClose={toggleConfirmOnboardModal}>
          {confirmOnboardModalContent}
        </Modal>
        <div className={classes.paper}>
          <Typography color="primary" component="h4">
            Add Partner
          </Typography>
          <form
            className={classes.form}
            margin="dense"
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  size="small"
                  required={true}
                  id="outlined-basic"
                  variant="outlined"
                  label="Raas location id: "
                  name="raas_location_id"
                  value={partnerSelected && partnerSelected.raas_location_id}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  required={true}
                  id="outlined-basic"
                  variant="outlined"
                  label="Name: "
                  name="name"
                  value={partnerSelected && partnerSelected.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="External name: "
                  name="external_name"
                  value={partnerSelected && partnerSelected.external_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Address: "
                  name="address"
                  value={partnerSelected && partnerSelected.address}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  size="small"
                  fullWidth
                  required={true}
                  id="outlined-basic"
                  variant="outlined"
                  label="Primary email: "
                  type="email"
                  name="primary_email"
                  value={partnerSelected && partnerSelected.primary_email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel shrink id="entityLabel">
                  Entity
                </InputLabel>
                <Select
                  fullWidth
                  id="outlined-select-entities"
                  multiple
                  label="Entity"
                  onChange={handleChange}
                  name="entities"
                  input={<Input />}
                  value={partnerSelected && partnerSelected.entities}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((item) => (
                        <Chip
                          key={item.pk + "chip"}
                          label={item.name}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                  helpertext="If the entity it is not in the list add a new one."
                >
                  {entities.map((option) => (
                    <MenuItem
                      key={option.pk}
                      value={option}
                      style={getStyles(option.name, partnerSelected, theme)}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel shrink id="brandLabel">
                  Brand
                </InputLabel>
                <Select
                  fullWidth
                  id="outlined-select-brands"
                  multiple
                  label="Brand"
                  onChange={handleChange}
                  name="brands"
                  input={<Input />}
                  value={partnerSelected && partnerSelected.brands}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((item) => (
                        <Chip
                          key={item.pk + "chip"}
                          label={item.name}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                  helpertext="If the entity it is not in the list add a new one."
                >
                  {brands.map((option) => (
                    <MenuItem
                      key={option.pk}
                      value={option}
                      style={getStyles(option.name, partnerSelected, theme)}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel shrink id="deliveryCompanies">
                  Delivery Companies
                </InputLabel>
                <Select
                  fullWidth
                  id="outlined-select-currency"
                  multiple
                  label="Delivery Company"
                  onChange={handleChange}
                  name="delivery_companies"
                  input={<Input />}
                  value={partnerSelected && partnerSelected.delivery_companies}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((item) => (
                        <Chip
                          key={item.delivery_company + "chip"}
                          label={item.delivery_company_name}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                  helpertext="If the company it is not in the list add a new one."
                >
                  {deliveryCompanies.map((option) => (
                    <MenuItem
                      key={option.delivery_company}
                      value={option}
                      style={getStyles(
                        option.delivery_company_name,
                        partnerSelected,
                        theme
                      )}
                    >
                      {option.delivery_company_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                {partnerSelected.invite_stripe ? (
                  <></>
                ) : (
                  <>
                    <InputLabel shrink id="stripeLabel">
                      Stripe
                    </InputLabel>
                    <Select
                      fullWidth
                      id="outlined-select-stripe"
                      key="select"
                      name="stripe"
                      label="Stripe"
                      input={<Input />}
                      value={partnerSelected && partnerSelected.stripe}
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>- None -</em>
                      </MenuItem>
                      {stripe
                        .filter((item) =>
                          item.stripe_account_id.includes("acct_")
                        )
                        .map((item) => (
                          <MenuItem
                            key={item.pk}
                            value={item.stripe_account_id}
                          >
                            <b>{item.stripe_account_id}</b>
                            {item.email ? (
                              <span>&nbsp;({item.email})</span>
                            ) : (
                              ""
                            )}
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                {partnerSelected.stripe ? (
                  <></>
                ) : (
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        name="invite_stripe"
                        value={partnerSelected && partnerSelected.invite_stripe}
                        checked={partnerSelected && partnerSelected.invite_stripe}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Invite to stripe"
                    labelPlacement="end"
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                {partnerSelected.invite_stripe_customer_id ? (
                  <></>
                ) : (
                  <>
                    <InputLabel shrink id="stripeCustomerIdLabel">
                      Stripe Customer
                    </InputLabel>
                    <Select
                      fullWidth
                      id="outlined-select-stripe-customer"
                      key="select"
                      name="stripe_customer_id"
                      label="Stripe Customer"
                      input={<Input />}
                      value={
                        partnerSelected && partnerSelected.stripe_customer_id
                      }
                      checked={
                        partnerSelected && partnerSelected.stripe_customer_id
                      }
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>- None -</em>
                      </MenuItem>
                      {stripe
                        .filter((item) =>
                          item.stripe_account_id.includes("cus_")
                        )
                        .map((item) => (
                          <MenuItem
                            key={item.pk}
                            value={item.stripe_account_id}
                          >
                            <b>{item.stripe_account_id}</b>
                            {item.email ? (
                              <span>&nbsp;({item.email})</span>
                            ) : (
                              ""
                            )}
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                {partnerSelected.stripe_customer_id ? (
                  <></>
                ) : (
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        name="invite_stripe_customer_id"
                        value={
                          partnerSelected &&
                          partnerSelected.invite_stripe_customer_id
                        }
                        checked={
                          partnerSelected &&
                          partnerSelected.invite_stripe_customer_id
                        }
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Invite to stripe customer"
                    labelPlacement="end"
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  disabled={partnerSelected.stripe === ""}
                  variant="contained"
                  color="primary"
                  onClick={toggleConfirmOnboardModal}
                >
                  Reset Onboard link
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
};

export default PartnerForm;
