import React, { useState, useEffect } from "react";
import InfiniteTable from "./InfiniteTable";
import {
  Container,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  /*   useTheme, */
} from "@mui/material";

/* const getStyles = (name, ordersConfig, theme) => {
  return {
    fontWeight:
      ordersConfig.brand.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}; */

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const OrdersPreview = (props) => {
  /*   const theme = useTheme(); */
  const {
    ordersData,
    ordersConfig,
    deliveryCompanies,
    brands,
    entities,
    partners,
    handleOrdersConfig,
    handleOrdersDataMapped,
  } = props;

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [invalidData, setInvalidData] = useState([]);
  const [columnsMapped, setColumnsMapped] = useState([]);
  const [dataMapped, setDataMapped] = useState([]);
  const [mapByColumn, setMapByColumn] = useState("index");

  useEffect(() => {
    if (ordersData.length > 0) {
      const ordersDataColumn = ordersData[0].data.map((item) => ({
        id: item,
        label: item,
      }));

      const ordersDataData = ordersData
        .filter((item, index) => index !== 0)
        .map((item) => item.data);
      setColumns(ordersDataColumn);
      setData(ordersDataData);
      setColumnsMapped(ordersDataColumn);
      setDataMapped(ordersDataData);
    }
  }, [ordersData]);

  useEffect(() => {
    if (ordersConfig.delivery_company !== "") {
      let deliveryCompany = deliveryCompanies.find(
        (item) => item.delivery_company_pk === ordersConfig.delivery_company
      );

      const deliveryCompanyMapping =
        typeof deliveryCompany.csv_header_mapping === "string"
          ? JSON.parse(deliveryCompany.csv_header_mapping)
          : deliveryCompany.csv_header_mapping;

      let dbColumnIndexing = [];
      let dbColumns = [];
      Object.keys(deliveryCompanyMapping).forEach((item) => {
        const ordersDataColumnIndex = columnsMapped.findIndex(
          (column, index) => item === column.id
        );
        dbColumnIndexing[deliveryCompanyMapping[item]] = Array.isArray(
          dbColumnIndexing[deliveryCompanyMapping[item]]
        )
          ? [].concat(
              ...dbColumnIndexing[deliveryCompanyMapping[item]],
              ordersDataColumnIndex
            )
          : [ordersDataColumnIndex];

        if (!dbColumns.includes(deliveryCompanyMapping[item])) {
          dbColumns.push(deliveryCompanyMapping[item]);
        }
      });

      let dbColumnsValidData = [];
      let dbColumnsInvalidData = [];
      dataMapped.forEach((row) => {
        let rowObj = {
          workflow_id: "",
          dining_mode: "",
          order_status: "",
          order_date: "",
          sales: 0,
          sales_tax: 0,
          adjustments: 0,
          adjustments_tax: 0,
          promotion_food: 0,
          promotion_food_tax: 0,
          promotion_delivery: 0,
          promotion_delivery_tax: 0,
          bag_fee: 0,
          marketing_fee_adjustment: 0,
          service_fee: 0,
          delivery_network_fee: 0,
          order_processing_fee: 0,
          dispatch_fee: 0,
          dispatch_fee_tax: 0,
          gratuity: 0,
          misc_payment_description: "",
          misc_payment: 0,
          marketplace_facilitator_t: 0,
          backup_withholding_tax: 0,
          stated_payout_date: "",
          stated_payout: 0,
        };
        let rowValid = true;
        dbColumns.forEach((item) => {
          const indexingValues = dbColumnIndexing[item];
          if (indexingValues.length > 1) {
            const numValues = indexingValues.every((val) => !isNaN(+row[val]));
            rowObj[item] = numValues
              ? indexingValues.reduce(
                  (value, current) => value + +row[current],
                  0
                )
              : indexingValues.reduce(
                  (value, current) =>
                    isNaN(value)
                      ? value + " " + row[current]
                      : value + (isNaN(+row[current]) ? 0 : +row[current]),
                  isNaN(rowObj[item]) ? "" : 0
                );
          } else {
            if (item.includes("_date")) {
              if (row[indexingValues] !== "") {
                const dateVal = new Date(row[indexingValues]);
                if (!isNaN(dateVal.getTime())) {
                  rowObj[item] = dateVal.toISOString().substring(0, 10);
                } else {
                  rowValid = false;
                }
              } else {
                rowValid = false;
              }
            } else if (item === "oid" && row[indexingValues] === "") {
              rowValid = false;
            } else if (item === "order_accept_time") {
              if (row[indexingValues] === "") {
                rowValid = false;
              } else {
                rowObj[item] = row[indexingValues];
              }
            } else if (item === "partner") {
              if (row[indexingValues] === "") {
                rowValid = false;
              } else {
                const orderStoreId = isNaN(+row[indexingValues])
                  ? row[indexingValues]
                  : +row[indexingValues];

                const defaultIdentifier =
                  deliveryCompany.default_identifier === "uuid"
                    ? "dcid"
                    : "store_id";

                const matchedPartner = partners.find((partner) =>
                  partner.delivery_companies.some(
                    (dcItem) =>
                      (isNaN(+dcItem[defaultIdentifier])
                        ? dcItem[defaultIdentifier]
                        : +dcItem[defaultIdentifier]) === orderStoreId &&
                      partner.brands &&
                      partner.brands.includes(ordersConfig.brand)
                  )
                );

                if (matchedPartner && matchedPartner.hasOwnProperty("pk")) {
                  rowObj[item] = matchedPartner.pk;
                } else {
                  rowValid = false;
                }
              }
            } else {
              if (row[indexingValues] === "") {
                rowObj[item] = 0;
              } else {
                rowObj[item] = row[indexingValues];
              }
            }
          }
        });

        if (rowValid) {
          dbColumnsValidData.push(rowObj);
        } else {
          dbColumnsInvalidData.push(rowObj);
        }
      });

      dbColumns = dbColumns.map((item) => ({
        id: item,
        label: item,
      }));

      setColumns(dbColumns);
      setData(dbColumnsValidData);
      setInvalidData(dbColumnsInvalidData);
      setMapByColumn("id");
    }
  }, [dataMapped, columnsMapped, deliveryCompanies, ordersConfig, partners]);

  useEffect(() => {
    handleOrdersDataMapped(data);
  }, [data, handleOrdersDataMapped]);

  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <InputLabel>Brand</InputLabel>
            <Select
              fullWidth
              label="Brand"
              onChange={handleOrdersConfig}
              name="brand"
              input={<Input />}
              value={ordersConfig && ordersConfig.brand}
              MenuProps={MenuProps}
            >
              {brands.map((option) => (
                <MenuItem key={"brand" + option.pk} value={option.pk}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel>Entity</InputLabel>
            <Select
              fullWidth
              label="Entity"
              onChange={handleOrdersConfig}
              name="entity"
              input={<Input />}
              value={ordersConfig && ordersConfig.entity}
              MenuProps={MenuProps}
            >
              {entities.map((option) => (
                <MenuItem key={"entity" + option.pk} value={option.pk}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel>Delivery company</InputLabel>
            <Select
              fullWidth
              label="Delivery company"
              onChange={handleOrdersConfig}
              name="delivery_company"
              MenuProps={MenuProps}
              input={<Input />}
              value={ordersConfig && ordersConfig.delivery_company}
            >
              {deliveryCompanies.map((option) => (
                <MenuItem
                  key={"delivery" + option.delivery_company_pk}
                  value={option.delivery_company_pk}
                >
                  {option.delivery_company_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <InfiniteTable
          apiep={null}
          columns={columns}
          data={data}
          dataMapByColumn={mapByColumn}
          deletable={false}
          editable={null}
          filterable={true}
          label="Orders"
          fetchingModal={null}
        />
      </Container>
      {invalidData.length > 0 ? (
        <Container>
          <InfiniteTable
            apiep={null}
            columns={columns}
            data={invalidData}
            dataMapByColumn={mapByColumn}
            deletable={false}
            editable={null}
            filterable={true}
            label="Invalid Orders"
            fetchingModal={null}
          />
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};
