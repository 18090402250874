import React from "react";
import InfiniteTable from "./InfiniteTable";
import { Container } from "@mui/material";

const columns = [
  { id: "from_date", label: "From Date" },
  { id: "to_date", label: "To Date" },
  { id: "payout_date", label: "Payout Date" },
  { id: "status", label: "Status" },
  { id: "basis", label: "Basis" },
  { id: "adjustments", label: "Adjustments" },
  { id: "tax_passed_through", label: "Tax passed through" },
  { id: "tax_not_passed_through", label: "Tax not passed through" },
  { id: "fees_absorbed", label: "Fees absorbed" },
  { id: "fees_not_absorbed", label: "Fees not absorbed" },
  { id: "raas_fees", label: "Raas fees" },
  { id: "extra_fees", label: "Extra fees" },
  { id: "total_payout", label: "Total Payout" },
  { id: "partner", label: "Partner" },
  { id: "entity", label: "Entity" },
];

export const PayoutCalculate = (props) => {
  const {
    selectedDateFrom,
    selectedDateTo,
    selectedDatePayout,
    selectedPartner,
    selectedEntity,
    selectedBrand,
    payin,
  } = props;

  return (
    <>
      <Container style={{ textAlign: "right" }}></Container>
      <Container>
        <InfiniteTable
          apiep={
            "data/api/calculatepayouts?from_date=" +
            selectedDateFrom +
            "&to_date=" +
            selectedDateTo +
            "&payout_date=" +
            selectedDatePayout +
            "&no_page=1" +
            (selectedPartner !== "" ? "&partner=" + selectedPartner : "") +
            (selectedEntity !== "" ? "&entity=" + selectedEntity : "") +
            (selectedBrand !== "" ? "&brand=" + selectedBrand : "") +
            (payin ? "&pay_in=" + payin : "")
          }
          columns={columns}
          data={null}
          dataMapByColumn="id"
          deletable={false}
          editable={null}
          filterable={true}
          label="Order Calculations"
          fetchingModal={{
            title: "Calculating",
            description:
              "This may take some time relative to the number of payouts.",
          }}
        />
      </Container>
    </>
  );
};
