import React from "react";
import InfiniteTable from "./InfiniteTable";
import { Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const columns = [
  { id: "from_date", label: "From Date" },
  { id: "to_date", label: "To Date" },
  { id: "payout_date", label: "Payout Date" },
  { id: "status", label: "Status" },
  { id: "basis", label: "Basis" },
  { id: "adjustments", label: "Adjustments" },
  { id: "tax_passed_through", label: "Tax passed through" },
  { id: "tax_not_passed_through", label: "Tax not passed through" },
  { id: "fees_absorbed", label: "Fees absorbed" },
  { id: "fees_not_absorbed", label: "Fees not absorbed" },
  { id: "raas_fees", label: "Raas fees" },
  { id: "extra_fees", label: "Extra fees" },
  { id: "total_payout", label: "Total Payout" },
  { id: "partner", label: "Partner" },
  { id: "entity", label: "Entity" },
];

export const Payout = () => {
  return (
    <>
      <Container style={{ textAlign: "right" }}>
        <Link
          to="/payout/add"
          style={{ textDecoration: "none" }}
          color="secondary"
        >
          <Button variant="contained" color="primary">
            <Typography variant="subtitle2" className="text-white">
              Make Payout
            </Typography>
          </Button>
        </Link>
      </Container>
      <Container>
        <InfiniteTable
          apiep="data/api/payouts/"
          columns={columns}
          data={null}
          dataMapByColumn="id"
          deletable={false}
          editable={false}
          filterable={true}
          label="Payouts"
          fetchingModal={null}
        />
      </Container>
    </>
  );
};
