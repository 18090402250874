import React from "react";
import InfiniteTable from "./InfiniteTable";
import { Container } from "@mui/material";

const columns = [
  { id: "oid", label: "Order ID" },
  {
    id: "order_date",
    label: "Order date",
  },
  { id: "partner_name", label: "Partner" },
  { id: "sales", label: "Sales" },
  {
    id: "stated_payout",
    label: "Stated payout",
  },
  {
    id: "stated_payout_date",
    label: "Stated payout date",
  },
  {
    id: "delivery_company_name",
    label: "Delivery company",
  },
];

export const PayoutRange = (props) => {
  const {
    selectedDateFrom,
    selectedDateTo,
    selectedPartner,
    selectedEntity,
    selectedBrand,
    payin,
  } = props;

  return (
    <>
      <Container style={{ textAlign: "right" }}></Container>
      <Container>
        <InfiniteTable
          apiep={
            "data/api/orders?from_date=" +
            selectedDateFrom +
            "&to_date=" +
            selectedDateTo +
            (selectedPartner !== "" ? "&partner=" + selectedPartner : "") +
            (selectedEntity !== "" ? "&entity=" + selectedEntity : "") +
            (selectedBrand !== "" ? "&brand=" + selectedBrand : "") +
            (payin ? "&pay_in=" + payin : "")
          }
          columns={columns}
          data={null}
          dataMapByColumn="id"
          deletable={false}
          editable={null}
          filterable={true}
          label="Orders"
          fetchingModal={null}
        />
      </Container>
    </>
  );
};
