import React, { useState, useEffect } from "react";
import InfiniteTable from "./InfiniteTable";
import { Container } from "@mui/material";

const columns = [
  { id: "partner_name", label: "Partner" },
  { id: "amount", label: "Amount" },
  { id: "description", label: "Description" },
  { id: "payin", label: "Pay In" },
  { id: "sendStatement", label: "Send Statement" },
];

export const OneOffReview = (props) => {
  const { partners, oneOffPayouts } = props;

  const [data, setData] = useState([]);

  useEffect(() => {
    if (oneOffPayouts.length) {
      const data = oneOffPayouts.map((item) => ({
        ...item,
        partner_name: partners.find((p) => p.pk === item.partner).name,
      }));

      setData(data);
    }
  }, [partners, oneOffPayouts]);

  return (
    <>
      <Container>
        <InfiniteTable
          apiep={null}
          columns={columns}
          data={data}
          dataMapByColumn="id"
          deletable={false}
          editable={null}
          filterable={true}
          label="One-Off Payouts Review"
          fetchingModal={null}
        />
      </Container>
    </>
  );
};
