import React, { Fragment } from "react";
import {
  Typography,
  Container,
  TextField,
  CssBaseline,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  iconButton: {
    padding: 10,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const EquationForm = (props) => {
  const classes = useStyles();
  const { handleChange, equationData } = props;
  const equationId = props.match.params.id;

  return (
    <Fragment>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography color="primary" component="h4">
            Add Equation
          </Typography>
          <form
            className={classes.form}
            margin="dense"
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  required={true}
                  disabled={!isNaN(equationId)}
                  id="outlined-basic"
                  variant="outlined"
                  label="Name: "
                  name="name"
                  value={equationData && equationData.name}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </Fragment>
  );
};
