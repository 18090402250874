import React, { useState, useEffect } from "react";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { backend, API_URL } from "../backend_api";
import { EquationSetup } from "./EquationSetup";
import { EquationForm } from "./EquationForm";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const EquationsProcess = (props) => {
  const classes = useStyles();
  const equationId = props.match.params.id;
  const steps = [
    (!isNaN(equationId) ? "" : "Add ") + "Equation",
    "Setup Equation",
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [orderHeaders, setOrderHeaders] = useState([]);
  const [equationHeaders, setEquationHeaders] = useState([]);
  const [equationData, setEquationData] = useState({ name: "" });
  const [equationOrderData, setEquationOrderData] = useState({});
  const [orderHeadersLeft, setOrderHeadersLeft] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEquationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const ordersGet = async () => {
      await backend
        .get(`${API_URL}data/api/ordersheaders`)
        .then((response) => {
          const orderHeaders = response.data.filter(
            (item) => !["pk"].includes(item)
          );
          setOrderHeaders(orderHeaders);
          setOrderHeadersLeft(orderHeaders);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const equationsGet = async () => {
      await backend
        .get(`${API_URL}data/api/equationheaders`)
        .then((response) => {
          setEquationHeaders(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    ordersGet();
    equationsGet();
  }, []);

  useEffect(() => {
    const createInitialData = () => {
      const equationDataSet = equationHeaders
        .filter((item) => !["pk", "structure", "name"].includes(item))
        .every((item) => equationData.hasOwnProperty(item));

      if (!equationDataSet) {
        let equationInitialData = {};
        equationHeaders
          .filter((item) => !["pk", "structure", "name"].includes(item))
          .forEach((item, index) => {
            equationInitialData[item] = [];
          });

        setEquationData((prevState) => ({
          ...prevState,
          ...equationInitialData,
        }));
      }
    };

    if (isNaN(equationId)) {
      createInitialData();
    }
  }, [equationId, equationData, equationHeaders]);

  useEffect(() => {
    const equationGet = async () => {
      await backend
        .get(`${API_URL}data/api/equations/${equationId}`)
        .then((response) => {
          let equationObj = response.data;

          equationHeaders
            .filter((item) => !["pk", "structure", "name"].includes(item))
            .forEach((item, index) => {
              equationObj = {
                ...equationObj,
                [item]:
                  typeof equationObj[item] === "string"
                    ? JSON.parse(equationObj[item])
                    : equationObj[item],
              };
            });

          setEquationData(equationObj);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (!isNaN(equationId) && !equationData.hasOwnProperty("pk")) {
      equationGet(equationId);
    }
  }, [equationId, equationData, equationHeaders]);

  useEffect(() => {
    const createEquationOrderData = () => {
      const equationDataSet = equationHeaders
        .filter((item) => !["pk", "structure"].includes(item))
        .every((item) => equationData.hasOwnProperty(item));

      if (equationDataSet) {
        let newEquationOrderData = {};
        let globalOrderHeadersMapped = [];
        equationHeaders
          .filter((item) => !["pk", "structure"].includes(item))
          .forEach(
            (item) =>
              (globalOrderHeadersMapped = [
                ...globalOrderHeadersMapped,
                ...equationData[item],
              ])
          );

        equationHeaders
          .filter((item) => !["pk", "structure"].includes(item))
          .forEach((item, index) => {
            const equationHeadersMapped = equationData[item];
            newEquationOrderData[item] =
              equationHeadersMapped.length > 0
                ? [
                    ...equationHeadersMapped,
                    ...orderHeaders.filter(
                      (item) =>
                        !equationHeadersMapped.includes(item) &&
                        !globalOrderHeadersMapped.includes(item)
                    ),
                  ]
                : orderHeaders.filter(
                    (item) =>
                      !equationHeadersMapped.includes(item) &&
                      !globalOrderHeadersMapped.includes(item)
                  );
          });

        setEquationOrderData(newEquationOrderData);
      }
    };

    createEquationOrderData();
  }, [equationData, equationHeaders, orderHeaders]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <EquationForm
            {...props}
            handleChange={handleChange}
            equationData={equationData}
          />
        );
      case 1:
        return (
          <EquationSetup
            {...props}
            handleChange={handleChange}
            orderHeaders={orderHeaders}
            equationHeaders={equationHeaders}
            equationData={equationData}
            orderHeadersLeft={orderHeadersLeft}
            equationOrderData={equationOrderData}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }
  const handleCancel = () => {
    props.history.push("/equations");
  };
  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      toggleConfirmModal();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      Are you sure you want to {props.match.params.id ? " update " : " add "}
      the equation <b>{equationData.name}</b>
      <div align="right">
        <Button onClick={() => toggleConfirmModal()} className={classes.button}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => sendRequest()}
          className={classes.button}
        >
          Confirm
        </Button>
      </div>
    </div>
  );

  const toggleResponseModal = () => setResponseModal(!responseModal);

  const responseModalContent = (
    <div className={classes.modal}>
      {errorInfo.error ? (
        <>
          There was an error
          {!isNaN(props.match.params.id) ? " updating " : " adding "}
          the equation <b>{equationData.name}</b>
          <br />
          <br />
          <span
            style={{
              color: "red",
              wordBreak: "break-word",
              maxHeight: 160,
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            <b>Error:</b>
            <br />
            {errorInfo.message}
          </span>
        </>
      ) : (
        <>
          Equation <b>{equationData.name}</b> has been successfully
          {!isNaN(props.match.params.id) ? " updated " : " added "}
        </>
      )}
      <div align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={() => resolveRequest()}
          className={classes.button}
        >
          Continue
        </Button>
      </div>
    </div>
  );

  const resolveRequest = () => {
    props.history.push("/equations");
  };

  const sendRequest = async () => {
    let equationObj = {
      // name: equationData.name,
    };
    equationHeaders.forEach((item) => {
      if (equationData.hasOwnProperty(item)) {
        equationObj = {
          ...equationObj,
          [item]: equationData[item],
        };
      }
    });

    await backend
      .post(`${API_URL + "data/api/equations/"}`, [equationObj])
      .then((response) => {
        toggleResponseModal();
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
        toggleResponseModal();
      });
  };

  return (
    <div>
      <>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Equations
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Modal open={confirmModal} onClose={toggleConfirmModal}>
              {confirmModalContent}
            </Modal>
            <Modal open={responseModal} onClose={toggleResponseModal}>
              {responseModalContent}
            </Modal>
            <>
              {activeStep === steps.length ? (
                <></>
              ) : (
                <>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    {!isNaN(equationId) && activeStep === steps.length - 1 ? (
                      <></>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    )}
                  </div>
                </>
              )}
            </>
          </Paper>
        </main>
      </>
    </div>
  );
};
