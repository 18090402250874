import axios from "axios";

const DEBUG = false

let URL
if (DEBUG) {
    URL = 'http://127.0.0.1:8000/';
} else {
    URL = 'https://admin.raas.com/api/';
}

export const AUTH_OBTAIN_ENDPOINT = 'auth/';
export const AUTH_VERIFY_ENDPOINT = 'verify/;';
export const AUTH_REFRESH_ENDPOINT = 'auth/refresh/';
export const AUTH_CURRENT_USER_ENDPOINT = 'currentuser/';
export const API_URL = URL;
export const backend = axios.create({baseURL : API_URL});
