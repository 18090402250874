import React, { Fragment, useState, useEffect } from "react";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EntityForm } from "./EntityForm";
import { EntityAccounting } from "./EntityAccounting";
import { backend, API_URL } from "../backend_api";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const EntityProcess = (props) => {
  const classes = useStyles();
  const steps = ["Entity Information", "Accounting Information"];
  const entityId = props.match.params.id;

  const [entity, setEntity] = useState({
    name: "",
    accounting_username: "",
    password: "",
    accounting_entity_id: "",
    accounting_company_id: "",
  });

  const [activeStep, setActiveStep] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });

  /*   const [entityError, setEntityError] = useState({
    name: false,
  }); */

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <EntityForm
            {...props}
            entity={entity}
            handleChange={handleChange}
            /* entityError={entityError} */
          />
        );
      case 1:
        return (
          <EntityAccounting
            {...props}
            entity={entity}
            handleChange={handleChange}
            /*             entityError={entityError} */
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const handleCancel = () => {
    props.history.push("/setup");
  };

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      toggleConfirmModal();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEntity((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      Are you sure you want to {!isNaN(entityId) ? " update " : " add "}
      the entity <b>{entity.name}</b>
      <div align="right">
        <Button onClick={() => toggleConfirmModal()} className={classes.button}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => sendRequest()}
          className={classes.button}
        >
          Confirm
        </Button>
      </div>
    </div>
  );

  const toggleResponseModal = () => setResponseModal(!responseModal);

  const responseModalContent = (
    <div className={classes.modal}>
      {errorInfo.error ? (
        <>
          There was an error
          {!isNaN(entityId) ? " updating " : " adding "}
          the entity <b>{entity.name}</b>
          <br />
          <br />
          <span
            style={{
              color: "red",
              wordBreak: "break-word",
              maxHeight: 160,
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            <b>Error:</b>
            <br />
            {errorInfo.message}
          </span>
        </>
      ) : (
        <>
          Entity <b>{entity.name}</b> has been successfully
          {!isNaN(entityId) ? " updated " : " added "}
        </>
      )}
      <div align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={() => resolveRequest()}
          className={classes.button}
        >
          Continue
        </Button>
      </div>
    </div>
  );

  const resolveRequest = () => {
    props.history.push("/setup");
  };

  const sendRequest = async () => {
    if (entityId && !isNaN(entityId)) {
      await backend
        .put(`${API_URL}data/api/entities/${props.match.params.id}`, entity)
        .then((response) => {
          toggleResponseModal();
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        });
    } else {
      await backend
        .post(`${API_URL + "data/api/entities/"}`, [entity])
        .then((response) => {
          toggleResponseModal();
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        });
    }
  };

  useEffect(() => {
    const entityGet = async () => {
      await backend
        .get(`${API_URL}data/api/entities/${entityId}`)
        .then((response) => {
          let entityObj = {
            name: response.data.name || "",
            accounting_username: response.data.accounting_username || "",
            password: response.data.password || "",
            accounting_entity_id: response.data.accounting_entity_id || "",
            accounting_company_id: response.data.accounting_company_id || "",
          };
          setEntity(entityObj);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (!isNaN(entityId)) {
      entityGet(entityId);
    }
  }, [entityId]);

  /*   const handleSubmit = (e) => {
    e.preventDefault();

    setEntityError((prevState) => ({
      ...prevState,
      name: false,
      accounting_username: false,
      password: false,
      accounting_entity_id: false,
    }));

    if (entity.name === "") {
      setEntityError((prevState) => ({
        ...prevState,
        name: true,
      }));
    } else {
      toggleConfirmModal();
    }
  }; */

  return (
    <div>
      <>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Entity
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <>
              <Modal open={confirmModal} onClose={toggleConfirmModal}>
                {confirmModalContent}
              </Modal>
              <Modal open={responseModal} onClose={toggleResponseModal}>
                {responseModalContent}
              </Modal>
              {getStepContent(activeStep)}
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  onClick={handleCancel}
                  className={classes.button}
                >
                  Cancel
                </Button>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} className={classes.button}>
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? "Save" : "Next"}
                </Button>
              </div>
            </>
          </Paper>
        </main>
      </>
    </div>
  );
};

export default EntityProcess;
