import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
  useTheme,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";

import * as Icons from "@mui/icons-material";

import { Switch, Route, Link, withRouter } from "react-router-dom";
import { AuthenticationContext } from "../auth/authcontext";

import { Dashboard } from "./Dashboard";
import { Partners } from "./Partners";
import { Orders } from "./Orders";
import { Payout } from "./Payout";
import { DeliveryProcess } from "./DeliveryProcess";
import { DeliverySettings } from "./DeliverySettings";
import { DeliveryCompany } from "./DeliveryCompany";
import { Stripe } from "./Stripe";

import { PartnerProcess } from "./PartnerProcess";
import { PayoutProcess } from "./PayoutProcess";
import { OneOff } from "./OneOff";
import { OneOffForm } from "./OneOffForm";
import { OneOffProcess } from "./OneOffProcess";
import { OrdersForm } from "./OrdersForm";
import { OrdersProcess } from "./OrdersProcess";
import { Equations } from "./Equations";
import { EquationsProcess } from "./EquationsProcess";
import { Search } from "./Search";
import { Setup } from "./Setup";
import Brands from "./Brands";
import { BrandProcess } from "./BrandProcess";
import EntityProcess from "./EntityProcess";
import { Fees } from "./Fees";
import { StripeForm } from "./StripeForm";

const linksData = [
  {
    text: "Dashboard",
    to: "dashboard",
    icon: "Dashboard",
    component: Dashboard,
  },
  { text: "Partners", to: "partners", icon: "Store", component: Partners },
  { text: "Orders", to: "orders", icon: "ShoppingBasket", component: Orders },
  {
    text: "Delivery Company",
    to: "deliverycompany",
    icon: "LocalTaxi",
    component: DeliveryCompany,
  },
  { text: "Payout", to: "payout", icon: "Payment", component: Payout },
  {
    text: "Stripe",
    to: "stripe",
    icon: "AccountBalanceWallet",
    component: Stripe,
  },
  {
    text: "Equations",
    to: "equations",
    icon: "ListAltOutlined",
    component: Equations,
  },
  {
    text: "Fees",
    to: "fees",
    icon: "ListAltOutlined",
    component: Fees,
  },
  {
    text: "Search",
    to: "search",
    icon: "Search",
    component: Search,
  },
  {
    text: "Set up",
    to: "setup",
    icon: "Settings",
    component: Setup,
  },
];

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "#8c9ea3",
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  menuItems: {
    textDecoration: "none",
    color: theme.palette.text.secondary,
  },
  noDecoration: {
    textDecoration: "none !important",
  },
}));

const Bar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  let auth = useContext(AuthenticationContext);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div
      className={classes.root}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          handleDrawer();
        }
      }}
    >
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            {React.createElement(Icons["Menu"])}
          </IconButton>
          <Box>
            <Typography variant="h6" noWrap>
              Raas Payment Engine mk. 0.2
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
          >
            <IconButton
              color="inherit"
              aria-label="Logout"
              onClick={auth.logout}
            >
              {React.createElement(Icons["ExitToApp"])}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        color="secondary"
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawer}>
            {theme.direction === "ltr"
              ? React.createElement(Icons["ChevronLeft"])
              : React.createElement(Icons["ChevronRight"])}
          </IconButton>
        </div>
        <Divider />
        <List key={"list"}>
          {linksData.map((item, index) => (
            <ListItem button key={index} component={Link} to={"/" + item.to}>
              <ListItemIcon>
                {React.createElement(Icons[item.icon])}
              </ListItemIcon>
              <ListItemText
                key={index.text + "text"}
                primary={
                  <Typography
                    key={index.text + "texttypo"}
                    variant="subtitle1"
                    className="text-white"
                  >
                    {item.text}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/equation/:id" component={EquationsProcess} />
          <Route
            exact
            path="/deliverycompanies/:id"
            component={DeliveryProcess}
          />
          <Route
            exact
            path="/deliverycompanies/:id/settings"
            component={DeliverySettings}
          />
          <Route
            exact
            path="/deliverycompanies/add"
            component={DeliveryProcess}
          />
          <Route exact path="/order/:id" component={OrdersForm} />
          <Route exact path="/orders/add" component={OrdersProcess} />
          <Route exact path="/partners/:id" component={PartnerProcess} />
          <Route exact path="/payout/add" component={PayoutProcess} />
          <Route exact path="/payout/sync" component={PayoutProcess} />
          <Route exact path="/oneoffs" component={OneOff} />
          <Route exact path="/oneoff/:id" component={OneOffForm} />
          <Route exact path="/oneoffs/add" component={OneOffProcess} />
          <Route path="/brands/:entityId" component={Brands} />
          <Route exact path="/entities/:id" component={EntityProcess} />
          <Route exact path="/brand/:entityId/:id/" component={BrandProcess} />
          <Route exact path="/brand/:id/" component={BrandProcess} />
          <Route exact path="/stripe/:id" component={StripeForm} />
          {linksData.map((item) => (
            <Route
              key={item.text}
              exact
              path={"/" + item.to}
              component={item.component}
            />
          ))}
        </Switch>
      </main>
    </div>
  );
};
const AppTopBar = withRouter(Bar);
export default AppTopBar;
