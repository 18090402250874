import React, { Fragment } from "react";
import {
  Container,
  Typography,
  CssBaseline,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 385,
  },
}));

export const PartnerSubscriptions = (props) => {
  const classes = useStyles();
  const {
    handleChangeSubscription,
    handleChangeSubscriptionDelete,
    partnerSelectedSubscription,
    partnerExtraSubscription,
    handleChangeExtraSubscription,
    handleChangeExtraSubscriptionAdd,
  } = props;

  return (
    <>
      <Container>
        <CssBaseline />
        <div className={classes.paper}>
          <form
            className={classes.form}
            margin="dense"
            noValidate
            autoComplete="off"
          >
            {partnerSelectedSubscription.length === 0 ? (
              <Typography
                color="primary"
                component="h4"
                style={{ textAlign: "center" }}
              >
                <b>There are no subscriptions added</b>
              </Typography>
            ) : (
              partnerSelectedSubscription.map((item, index) => (
                <Fragment key={index}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                      {"Subscription " + (index + 1)}
                      <Button
                        style={{
                          minWidth: "auto",
                          padding: "6px 10px",
                        }}
                        onClick={handleChangeSubscriptionDelete(index, item)}
                      >
                        Delete
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        size="small"
                        required={true}
                        id="outlined-basic"
                        variant="outlined"
                        label="Description: "
                        key={"description-" + index}
                        name={"description-" + index}
                        value={item.description && item.description}
                        onChange={handleChangeSubscription(index)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        size="small"
                        required={true}
                        id="outlined-basic"
                        variant="outlined"
                        label="Flat amount: "
                        key={"flat_amount-" + index}
                        name={"flat_amount-" + index}
                        value={item.flat_amount && item.flat_amount}
                        onChange={handleChangeSubscription(index)}
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              ))
            )}
            <Grid container spacing={2} style={{ marginTop: 24 }}>
              <Grid
                container
                alignItems={"center"}
                style={{ justifyContent: "center" }}
              >
                <Typography color="primary" component="h4">
                  Add a subscription with the following information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Description: "
                  name="description"
                  value={
                    partnerExtraSubscription &&
                    partnerExtraSubscription.description
                  }
                  onChange={handleChangeExtraSubscription}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Flat amount: "
                  name="flat_amount"
                  value={
                    partnerExtraSubscription &&
                    partnerExtraSubscription.flat_amount
                  }
                  onChange={handleChangeExtraSubscription}
                />
              </Grid>
              <Grid container style={{ justifyContent: "flex-end" }}>
                <Button
                  style={{
                    minWidth: "auto",
                    marginTop: 10,
                    padding: "6px 10px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleChangeExtraSubscriptionAdd}
                  disabled={partnerExtraSubscription.flat_amount === ""}
                >
                  Add Subscription
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
};
