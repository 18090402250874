import React, { Fragment } from "react";

import {
  Typography,
  Container,
  TextField,
  CssBaseline,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Divider,
  Input
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

export const OneOffPayout = (props) => {
  const classes = useStyles();
  const {
    partners,
    entities,
    entitiesFull,
    oneOffPayouts,
    extraOneOffPayout,
    payinAll,
    sendStatementAll,
    handleOneOffPayoutsChange,
    handleExtraOneOffPayoutChange,
    handleExtraOneOffPayoutAddChange,
    handlePayinAllChange,
    handleSendStatementAllChange,
  } = props;

  return (
    <>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <div className={classes.paper}>
          <form className={classes.form} margin="dense" autoComplete="off">
            <Grid container spacing={2} style={{ alignItems: "center" }}>
              <Grid item xs={12} sm={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <InputLabel shrink id="partnerLabel">
                      Partner
                    </InputLabel>
                    <Select
                      fullWidth
                      className={classes.formControl}
                      key="partner"
                      name="partner"
                      label="partner"
                      input={<Input />}
                      value={
                        (extraOneOffPayout && extraOneOffPayout.partner) || ""
                      }
                      onChange={handleExtraOneOffPayoutChange}
                      MenuProps={MenuProps}
                    >
                      {partners.map((item) => (
                        <MenuItem key={item.pk} value={item.pk}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <InputLabel shrink id="entityLabel">
                      Entity
                    </InputLabel>
                    <Select
                      fullWidth
                      className={classes.formControl}
                      key="entity"
                      name="entity"
                      label="entity"
                      input={<Input />}
                      value={
                        (extraOneOffPayout && extraOneOffPayout.entity) || ""
                      }
                      onChange={handleExtraOneOffPayoutChange}
                      MenuProps={MenuProps}
                    >
                      {entities.map((item) => (
                        <MenuItem key={item.pk} value={item.pk}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Checkbox
                          name="payin"
                          value={extraOneOffPayout && extraOneOffPayout.payin}
                          checked={extraOneOffPayout && extraOneOffPayout.payin}
                          onChange={handleExtraOneOffPayoutChange}
                          color="primary"
                        />
                      }
                      label="Pay In"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      size="small"
                      required={true}
                      variant="outlined"
                      label="Description: "
                      name="description"
                      value={extraOneOffPayout && extraOneOffPayout.description}
                      onChange={handleExtraOneOffPayoutChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      size="small"
                      fullWidth
                      required
                      variant="outlined"
                      label="Amount: "
                      type="number"
                      name="amount"
                      value={extraOneOffPayout && extraOneOffPayout.amount}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={handleExtraOneOffPayoutChange}
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Checkbox
                          name="sendStatement"
                          value={
                            extraOneOffPayout && extraOneOffPayout.sendStatement
                          }
                          checked={
                            extraOneOffPayout && extraOneOffPayout.sendStatement
                          }
                          onChange={handleExtraOneOffPayoutChange}
                          color="primary"
                        />
                      }
                      label="Send Statement"
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3} style={{ justifyContent: "flex-end" }}>
                <Button
                  style={{
                    minWidth: "auto",
                    marginTop: 10,
                    padding: "6px 10px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleExtraOneOffPayoutAddChange}
                  disabled={
                    extraOneOffPayout.partner === "" ||
                    extraOneOffPayout.amount === "" ||
                    extraOneOffPayout.description === ""
                  }
                >
                  Add One-Off
                </Button>
              </Grid>
            </Grid>
            <Divider style={{ margin: "10px 0px" }} />
            <Grid container spacing={2} style={{ alignItems: "center", marginBottom: 10 }}>
              <Grid item xs={12} sm={7}>
                <Typography color="primary" variant="body2">
                  There are{" "}
                  <b>{!oneOffPayouts.length ? " no " : oneOffPayouts.length}</b>{" "}
                  One-Off Payouts configured
                </Typography>
              </Grid>
              {!oneOffPayouts.length ? (
                <></>
              ) : (
                <>
                  <Grid item xs={12} sm={2}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Checkbox
                          name="payinAll"
                          value={payinAll}
                          checked={payinAll}
                          onChange={handlePayinAllChange}
                          color="primary"
                        />
                      }
                      label="Pay In all"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Checkbox
                          name="sendStatementAll"
                          value={sendStatementAll}
                          checked={sendStatementAll}
                          onChange={handleSendStatementAllChange}
                          color="primary"
                        />
                      }
                      label="Send Statement all"
                      labelPlacement="end"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {oneOffPayouts.map((item, idx) => (
              <Fragment key={idx + "_container"}>
                {idx !== 0 ? (
                  <Divider style={{ margin: "10px 10px" }} />
                ) : (
                  <></>
                )}
                <Grid container spacing={2} style={{ alignItems: "center" }}>
                  <Grid item xs={12} sm={1}>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        color="primary"
                        variant="h6"
                        style={{
                          textAlign: "center",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        {idx + 1}
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}></Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8}>
                        <InputLabel shrink id="partnerLabel">
                          Partner
                        </InputLabel>
                        <Select
                          fullWidth
                          className={classes.formControl}
                          key="partner"
                          name="partner"
                          label="partner"
                          input={<Input />}
                          value={(item && item.partner) || ""}
                          onChange={handleOneOffPayoutsChange(idx)}
                          MenuProps={MenuProps}
                        >
                          {partners.map((item) => (
                            <MenuItem key={idx + "_" + item.pk} value={item.pk}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <InputLabel shrink id="entityLabel">
                          Entity
                        </InputLabel>
                        <Select
                          fullWidth
                          className={classes.formControl}
                          key="entity"
                          name="entity"
                          label="entity"
                          input={<Input />}
                          value={(item && item.entity) || ""}
                          onChange={handleOneOffPayoutsChange(idx)}
                          MenuProps={MenuProps}
                        >
                          {entitiesFull
                            .filter((entity) =>
                              partners
                                .find((partner) => partner.pk === item.partner)
                                .entities.includes(entity.pk)
                            )
                            .map((item) => (
                              <MenuItem
                                key={idx + "_" + item.pk}
                                value={item.pk}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                          fullWidth
                          size="small"
                          required={true}
                          variant="outlined"
                          label="Description: "
                          name="description"
                          value={item && item.description}
                          onChange={handleOneOffPayoutsChange(idx)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          size="small"
                          fullWidth
                          required
                          variant="outlined"
                          label="Amount: "
                          type="number"
                          name="amount"
                          value={item && item.amount}
                          InputProps={{ inputProps: { min: 0 } }}
                          onChange={handleOneOffPayoutsChange(idx)}
                          onKeyPress={(event) => {
                            if (event?.key === "-" || event?.key === "+") {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Checkbox
                          name="payin"
                          value={item && item.payin}
                          checked={item && item.payin}
                          onChange={handleOneOffPayoutsChange(idx)}
                          color="primary"
                        />
                      }
                      label="Pay In"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Checkbox
                          name="sendStatement"
                          value={item && item.sendStatement}
                          checked={item && item.sendStatement}
                          onChange={handleOneOffPayoutsChange(idx)}
                          color="primary"
                        />
                      }
                      label="Send Statement"
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
              </Fragment>
            ))}
          </form>
        </div>
      </Container>
    </>
  );
};
