import React, { Fragment, useState, useEffect } from "react";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Modal,
  IconButton,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import { DeliveryForm } from "./DeliveryForm";
import { DeliveryUpload } from "./DeliveryUpload";
import { DeliveryMapping } from "./DeliveryMapping";
import { backend, API_URL } from "../backend_api";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const DeliveryProcess = (props) => {
  const classes = useStyles();
  const steps = ["Add Delivery Company", "Upload Headers", "Mapping"];
  const companyId = props.match.params.id;

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [companySelected, setCompanySelected] = useState({
    name: "",
    headers: [],
    csv_header_mapping: {},
  });
  const [orderHeadersSelected, setOrderHeadersSelected] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });
  const [headerMapping, setHeaderMapping] = useState({});
  const [headerExtraMapping, setHeaderExtraMapping] = useState({
    extra_text: "",
    extra_select: "",
  });

  const isStepOptional = (step) => {
    const optionalStep = !isNaN(companyId) ? 1 : null;
    return step === optionalStep;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCompanySelected((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMappingChange = (e) => {
    const { name, value } = e.target;

    const orderHeaderName =
      companySelected.headers[name.replace("select-", "")];

    setHeaderMapping((prevState) => ({
      ...prevState,
      [orderHeaderName]: value,
    }));
  };

  const handleExtraMappingChange = (e) => {
    const { name, value } = e.target;

    setHeaderExtraMapping((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMappingAddChange = () => {
    setCompanySelected((prevState) => ({
      ...prevState,
      headers: [...companySelected.headers, headerExtraMapping.extra_text],
    }));

    setHeaderMapping((prevState) => ({
      ...prevState,
      [headerExtraMapping.extra_text]: headerExtraMapping.extra_select,
    }));

    setHeaderExtraMapping({
      extra_text: "",
      extra_select: "",
    });
  };

  const handleMappingRemoveChange = (idx, header) => (e) => {
    setCompanySelected((prevState) => ({
      ...prevState,
      headers: companySelected.headers.filter((item) => item !== header),
    }));

    setHeaderMapping((prevState) => {
      delete prevState[header];
      return prevState;
    });

    setHeaderExtraMapping({
      extra_text: "",
      extra_select: "",
    });
  };

  useEffect(() => {
    handleChange({
      target: {
        name: "csv_header_mapping",
        value: headerMapping,
      },
    });
  }, [headerMapping]);

  useEffect(() => {
    const ordersGet = async () => {
      await backend
        .get(`${API_URL}data/api/ordersheaders`)
        .then((response) => {
          const orderHeaders = response.data.filter(
            (item) => !["pk"].includes(item)
          );
          setOrderHeadersSelected(orderHeaders);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    ordersGet();
  }, []);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <DeliveryForm
            {...props}
            companySelected={companySelected}
            handleChange={handleChange}
          />
        );
      case 1:
        return <DeliveryUpload {...props} companySelected={companySelected} />;
      case 2:
        return (
          <DeliveryMapping
            {...props}
            companySelected={companySelected}
            handleChange={handleChange}
            handleExtraMappingChange={handleExtraMappingChange}
            handleMappingAddChange={handleMappingAddChange}
            handleMappingRemoveChange={handleMappingRemoveChange}
            handleMappingChange={handleMappingChange}
            headerExtraMapping={headerExtraMapping}
            headerMapping={headerMapping}
            orderHeadersSelected={orderHeadersSelected}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }
  const handleCancel = () => {
    props.history.push("/deliverycompany");
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (activeStep !== steps.length - 1) {
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep(activeStep + 1);
      setSkipped(newSkipped);
    } else {
      toggleConfirmModal();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      Are you sure you want to {!isNaN(companyId) ? " update " : " add "}
      the delivery company <b>{companySelected.name}</b>
      <div align="right">
        <Button onClick={() => toggleConfirmModal()} className={classes.button}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => sendRequest()}
          className={classes.button}
        >
          Confirm
        </Button>
      </div>
    </div>
  );

  const toggleResponseModal = () => setResponseModal(!responseModal);

  const responseModalContent = (
    <div className={classes.modal}>
      {errorInfo.error ? (
        <>
          There was an error
          {!isNaN(companyId) ? " updating " : " adding "}
          the delivery company <b>{companySelected.name}</b>
          <br />
          <br />
          <span
            style={{
              color: "red",
              wordBreak: "break-word",
              maxHeight: 160,
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            <b>Error:</b>
            <br />
            {errorInfo.message}
          </span>
        </>
      ) : (
        <>
          Delivery company <b>{companySelected.name}</b> has been successfully
          {!isNaN(companyId) ? " updated " : " added "}
        </>
      )}
      <div align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={() => resolveRequest()}
          className={classes.button}
        >
          Continue
        </Button>
      </div>
    </div>
  );

  const resolveRequest = () => {
    props.history.push("/deliverycompany");
  };

  const sendRequest = async () => {
    const companyObj = {
      csv_header_mapping: JSON.stringify(companySelected.csv_header_mapping),
      name: companySelected.name,
    };

    if (companyId && !isNaN(companyId)) {
      await backend
        .put(
          `${API_URL}data/api/deliverycompanies/${props.match.params.id}`,
          companyObj
        )
        .then((response) => {
          toggleResponseModal();
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        });
    } else {
      await backend
        .post(`${API_URL + "data/api/deliverycompanies/"}`, [companyObj])
        .then((response) => {
          toggleResponseModal();
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        });
    }
  };

  useEffect(() => {
    const deliveryCompanyGet = async () => {
      await backend
        .get(`${API_URL}data/api/deliverycompanies/${companyId}`)
        .then((response) => {
          let headersArray = [];
          if (response.data.csv_header_mapping != null) {
            const mappingObj =
              typeof response.data.csv_header_mapping === "string"
                ? JSON.parse(response.data.csv_header_mapping)
                : response.data.csv_header_mapping;
            headersArray = Object.keys(mappingObj);
            setHeaderMapping(mappingObj);
          }
          let companyObj = {
            pk: response.data.id,
            name: response.data.name,
            headers: headersArray,
            csv_header_mapping: response.data.csv_header_mapping,
          };
          if (companyId) {
            companyObj = { ...companyObj, pk: response.data.pk };
          }
          setCompanySelected(companyObj);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (!isNaN(companyId)) {
      deliveryCompanyGet(companyId);
    }
  }, [companyId]);

  return (
    <div>
      <Fragment>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <div style={{ display: "flex" }}>
              <Typography component="h1" variant="h4" align="center" style={{flexGrow: 1}}>
                Delivery Company
              </Typography>
              <Tooltip
                title={(companySelected && companySelected.name) + " settings"}
              >
                <IconButton
                  aria-label={
                    (companySelected && companySelected.name) + " settings"
                  }
                  onClick={(e) => {
                    props.history.push(
                      `/deliverycompanies/${props.match.params.id}/settings`
                    );
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Modal open={confirmModal} onClose={toggleConfirmModal}>
              {confirmModalContent}
            </Modal>
            <Modal open={responseModal} onClose={toggleResponseModal}>
              {responseModalContent}
            </Modal>
            <Fragment>
              {activeStep === steps.length ? (
                <Fragment></Fragment>
              ) : (
                <Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    {isStepOptional(activeStep) && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSkip}
                        className={classes.button}
                      >
                        Skip
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </Paper>
        </main>
      </Fragment>
    </div>
  );
};
