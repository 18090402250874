import React from "react";
import {
  Typography,
  Grid,
  Select,
  Input,
  MenuItem,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material/";
import { makeStyles } from "@mui/styles";

import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const PayoutDates = (props) => {
  const classes = useStyles();
  const {
    action,
    partners,
    entities,
    brands,
    handleDateFromChange,
    handleDateToChange,
    handleDatePayoutChange,
    handlePartnerChange,
    handleEntityChange,
    handleBrandChange,
    handlePayinChange,
    handleSendStatementsChange,
    selectedDateFrom,
    selectedDateTo,
    selectedDatePayout,
    selectedPartner,
    selectedEntity,
    selectedBrand,
    payin,
    sendStatements,
  } = props;

  return (
    <>
      <div className={classes.paper}>
        <Grid container>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={4}>
              <Typography color="primary" component="h4">
                Select a partner for the payout
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Select
                fullWidth
                label="Partner"
                onChange={handlePartnerChange}
                name="partner"
                input={<Input />}
                value={selectedPartner && selectedPartner}
                MenuProps={MenuProps}
              >
                <MenuItem value="">
                  <em>- All -</em>
                </MenuItem>
                {partners.map((option) => (
                  <MenuItem key={"partner" + option.pk} value={option.pk}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={4}>
              <Typography color="primary" component="h4">
                Select an entity for the payout
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Select
                fullWidth
                label="Entity"
                onChange={handleEntityChange}
                name="entity"
                input={<Input />}
                value={selectedEntity && selectedEntity}
                MenuProps={MenuProps}
              >
                <MenuItem value="">
                  <em>- All -</em>
                </MenuItem>
                {entities.map((option) => (
                  <MenuItem key={"entity" + option.pk} value={option.pk}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          {action !== "retry" ? (
            <Grid container alignItems="center">
              <Grid item xs={12} sm={4}>
                <Typography color="primary" component="h4">
                  Select a brand for the payout
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  fullWidth
                  label="Brand"
                  onChange={handleBrandChange}
                  name="brand"
                  input={<Input />}
                  value={selectedBrand && selectedBrand}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">
                    <em>- All -</em>
                  </MenuItem>
                  {brands.map((option) => (
                    <MenuItem key={"brand" + option.pk} value={option.pk}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Grid container style={{ marginTop: 10 }} alignItems="center">
            {!["retry", "billing", "sync"].includes(action) ? (
              <>
                <Grid item xs={12} sm={4}>
                  <Typography color="primary" component="h4">
                    Select date for the payout
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="payout-date-picker-inline"
                      value={selectedDatePayout && selectedDatePayout}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          label="Select payout date"
                          variant="standard"
                        />
                      )}
                      onChange={handleDatePayoutChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={8}></Grid>
            )}
            <Grid item xs={12} sm={4}>
              {!["billing", "sync"].includes(action) ? (
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      name="payin"
                      value={payin}
                      checked={payin}
                      onChange={handlePayinChange}
                      color="primary"
                    />
                  }
                  label="Pay in"
                  labelPlacement="end"
                />
              ) : (
                <></>
              )}
              {!["sync"].includes(action) ? (
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      name="sendStatements"
                      value={sendStatements}
                      checked={sendStatements}
                      onChange={handleSendStatementsChange}
                      color="primary"
                    />
                  }
                  label="Send statements"
                  labelPlacement="end"
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          {!["billing"].includes(action) ? (
            <Grid container style={{ marginTop: 10 }} alignItems="center">
              <Grid item xs={12} sm={4}>
                <Typography color="primary" component="h4">
                  Select the range of dates
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="from-date-picker-inline"
                    value={selectedDateFrom && selectedDateFrom}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        label="Select start date"
                        variant="standard"
                      />
                    )}
                    onChange={handleDateFromChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="to-date-picker-inline"
                    value={selectedDateTo && selectedDateTo}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        label="Select end date"
                        variant="standard"
                      />
                    )}
                    onChange={handleDateToChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </div>
    </>
  );
};
