import React from "react";
import { Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CSVReader } from "react-papaparse";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export const OrdersUpload = (props) => {
  const classes = useStyles();
  const { handleOrdersData } = props;

  const handleOnDrop = (data) => {
    let ordersData = [];
    data.forEach((item) => {
      if (item.data.length > 1) {
        ordersData.push(item);
      }
    });
    handleOrdersData(ordersData);
  };

  return (
    <div className={classes.root}>
      <Typography color="primary" component="h4">
        Upload orders
      </Typography>

      <Grid item xs={12}>
        <CSVReader onDrop={handleOnDrop} noDrop></CSVReader>
      </Grid>
    </div>
  );
};
