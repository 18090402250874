import React from "react";
import InfiniteTable from "./InfiniteTable";
import { Container } from "@mui/material";

const columns = [
  { id: "description", label: "Description" },
  { id: "flat_amount", label: "Flat amount" },
  { id: "partner", label: "Partner" },
];

export const PayoutSubscriptions = (props) => {
  const { selectedPartner, selectedEntity, selectedBrand } = props;

  return (
    <>
      <Container style={{ textAlign: "right" }}></Container>
      <Container>
        <InfiniteTable
          apiep={
            "data/api/subscriptions?no_page=1" +
            (selectedPartner !== "" ? "&partner=" + selectedPartner : "") +
            (selectedEntity !== "" ? "&entity=" + selectedEntity : "") +
            (selectedBrand !== "" ? "&brand=" + selectedBrand : "")
          }
          columns={columns}
          data={null}
          dataMapByColumn="id"
          deletable={false}
          editable={null}
          filterable={true}
          label="Subscriptions"
          fetchingModal={null}
        />
      </Container>
    </>
  );
};
