import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  TextField,
  CssBaseline,
  Grid,
  Button,
  CircularProgress,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { backend, API_URL } from "../backend_api";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const StripeForm = (props) => {
  const classes = useStyles();

  const [partners, setPartners] = useState([]);
  const [stripeSelected, setStripeSelected] = useState({
    stripe_account_id: "",
    valid: false,
    bank_account: "",
    charges_enabled: false,
    email: "",
    payouts_enabled: false,
    disabled_reason: "",
    partners: "",
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmOnboardModal, setConfirmOnboardModal] = useState(false);
  const toggleConfirmModal = () => setConfirmModal(!confirmModal);
  const toggleConfirmOnboardModal = () =>
    setConfirmOnboardModal(!confirmOnboardModal);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [finishedFlag, setFinishedFlag] = useState(false);

  useEffect(() => {
    const partnerGet = async () => {
      await backend
        .get(`${API_URL}data/api/partners?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => {
            return {
              pk: item.pk,
              name: item.name,
              stripe: item.stripe,
              stripe_customer_id: item.stripe_customer_id,
            };
          });
          setPartners(dataMap);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    partnerGet();
  }, []);

  useEffect(() => {
    const stripeGet = async (props) => {
      await backend
        .get(`${API_URL}data/api/stripesetups/${props.match.params.id}`)
        // .get(`${API_URL}data/api/stripesetups?no_page=1`)
        .then(({ data }) => {
          let partnersMatched = partners.filter(
            (item) =>
              item.stripe === props.match.params.id ||
              item.stripe_customer_id === props.match.params.id
          );
          setStripeSelected({
            stripe_account_id: data.stripe_account_id || "",
            valid: data.valid || false,
            bank_account: data.bank_account || "",
            charges_enabled: data.charges_enabled || false,
            email: data.email || "",
            payouts_enabled: data.payouts_enabled || false,
            disabled_reason: data.disabled_reason || "",
            partners: partnersMatched.length
              ? partnersMatched.reduce(
                  (previous, current) =>
                    previous + (previous !== "" ? ", " : "") + current.name,
                  ""
                )
              : "",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (props.match.params.id && partners.length > 0) {
      stripeGet(props);
    }
  }, [partners, props]);

  const confirmModalContent = (
    <div className={classes.modal}>
      {!loadingFlag && !finishedFlag ? (
        <>
          Are you sure you want to delete stripe account id{" "}
          <b>{stripeSelected.stripe_account_id}</b>?
          <br />
          <br />
          <span
            style={{
              color: "red",
              wordBreak: "break-word",
              maxHeight: 160,
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            <b>Warning:</b>
            <br />
            This action will delete this account from Stripe and from the
            platform
          </span>
          <div align="right">
            <Button
              onClick={() => toggleConfirmModal()}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => sendRequest()}
              className={classes.button}
            >
              Confirm
            </Button>
          </div>
        </>
      ) : loadingFlag ? (
        <>
          <div align="center">
            <CircularProgress />
            <div>Deleting...</div>
          </div>
          {errorInfo.error ? (
            <>
              <br />
              <br />
              There was an error while deleting stripe account id{" "}
              <b>{stripeSelected.stripe_account_id}</b>
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  wordBreak: "break-word",
                  maxHeight: 160,
                  overflow: "hidden",
                  display: "inline-block",
                }}
              >
                <b>Error:</b>
                <br />
                {errorInfo.message}
              </span>
              <div align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => resolveRequest()}
                  className={classes.button}
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          Stripe account id <b>{stripeSelected.stripe_account_id}</b> has been
          deleted successfully
          <div align="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => resolveRequest()}
              className={classes.button}
            >
              Close
            </Button>
          </div>
        </>
      )}
    </div>
  );

  const confirmOnboardModalContent = (
    <div className={classes.modal}>
      {!loadingFlag && !finishedFlag ? (
        <>
          Are you sure you want to reset <b>onboard link</b>?
          <div align="right">
            <Button
              onClick={() => toggleConfirmOnboardModal()}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => sendOnboardRequest()}
              className={classes.button}
            >
              Confirm
            </Button>
          </div>
        </>
      ) : loadingFlag ? (
        <>
          <div align="center">
            <CircularProgress />
            <div>Refreshing...</div>
          </div>
          {errorInfo.error ? (
            <>
              <br />
              <br />
              There was an error while refreshing onboard link
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  wordBreak: "break-word",
                  maxHeight: 160,
                  overflow: "hidden",
                  display: "inline-block",
                }}
              >
                <b>Error:</b>
                <br />
                {errorInfo.message}
              </span>
              <div align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => resolveRequest()}
                  className={classes.button}
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          Onboard link has been refreshed successfully
          <div align="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => resolveRequest()}
              className={classes.button}
            >
              Close
            </Button>
          </div>
        </>
      )}
    </div>
  );

  const resolveRequest = () => {
    toggleConfirmModal();
    toggleConfirmOnboardModal();
    setFinishedFlag(false);
    props.history.push("/stripe");
  };

  const sendRequest = async () => {
    setLoadingFlag(true);
    await backend
      .delete(
        `${API_URL}data/api/stripesetups/${stripeSelected.stripe_account_id}`
      )
      .then((response) => {
        setLoadingFlag(false);
        setFinishedFlag(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
      });
  };

  const sendOnboardRequest = async () => {
    setLoadingFlag(true);
    await backend
      .put(`${API_URL}data/api/stripegeneratelink/`, null, {
        params: {
          account_id: stripeSelected.stripe_account_id,
        },
      })
      .then((response) => {
        setLoadingFlag(false);
        setFinishedFlag(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Modal open={confirmModal} onClose={toggleConfirmModal}>
          {confirmModalContent}
        </Modal>
        <Modal open={confirmOnboardModal} onClose={toggleConfirmOnboardModal}>
          {confirmOnboardModalContent}
        </Modal>
        <div className={classes.paper}>
          <Typography color="primary" component="h4">
            Stripe Account Details
          </Typography>
          <form
            className={classes.form}
            margin="dense"
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Stripe account id: "
                  name="stripe_account_id"
                  value={stripeSelected && stripeSelected.stripe_account_id}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Bank account: "
                  name="promotion_food"
                  value={stripeSelected && stripeSelected.bank_account}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Email: "
                  name="email"
                  value={stripeSelected && stripeSelected.email}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Disabled reason: "
                  name="disabled_reason"
                  value={stripeSelected && stripeSelected.disabled_reason}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Partners: "
                  name="partners"
                  value={stripeSelected && stripeSelected.partners}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={toggleConfirmModal}
                >
                  Delete Account
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={toggleConfirmOnboardModal}
                >
                  Reset Onboard Link
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
};
