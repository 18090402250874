import React, { useState, useEffect } from "react";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { backend, API_URL } from "../backend_api";
import { OrdersUpload } from "./OrdersUpload";
import { OrdersPreview } from "./OrdersPreview";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      width: 1080,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const OrdersProcess = (props) => {
  const classes = useStyles();
  const steps = ["Upload Orders", "Preview Orders"];
  const [activeStep, setActiveStep] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });
  const [ordersData, setOrdersData] = useState([]);
  const [ordersConfig, setOrdersConfig] = useState({
    brand: "",
    delivery_company: "",
    entity: "",
  });
  const [deliveryCompanies, setDeliveryCompanies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [entities, setEntities] = useState([]);
  const [partners, setPartners] = useState([]);
  const [partnersRemapped, setPartnersRemapped] = useState(false);
  const [partnersConfigurations, setPartnersConfigurations] = useState([]);
  const [partnersEntities, setPartnersEntities] = useState([]);
  const [partnersBrands, setPartnersBrands] = useState([]);
  const [ordersDataMapped, setOrdersDataMapped] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [finishedFlag, setFinishedFlag] = useState(false);

  const handleOrdersConfig = (e) => {
    const { name, value } = e.target;
    setOrdersConfig((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOrdersData = (data) => {
    setOrdersData(data);
  };

  const handleOrdersDataMapped = (data) => {
    setOrdersDataMapped(data);
  };

  useEffect(() => {
    const getPartners = async () => {
      await backend
        .get(`${API_URL}data/api/partners?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => {
            /*let delivery_companies = item.delivery_companies.map((dcItem) => ({
              delivery_company: dcItem.delivery_company,
              store_id: dcItem.store_id,
            }));*/
            return {
              pk: item.pk,
              /*delivery_companies: delivery_companies,
              entities: item.entities,
              brands: item.brands,*/
            };
          });
          setPartners(dataMap);
        });
    };

    const getBrands = async () => {
      await backend
        .get(`${API_URL}data/api/brands?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => ({
            pk: item.pk,
            name: item.name,
          }));
          setBrands(dataMap);
        });
    };

    const getEntities = async () => {
      await backend
        .get(`${API_URL}data/api/entities?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => ({
            pk: item.pk,
            name: item.name,
          }));
          setEntities(dataMap);
        });
    };

    const getCompanies = async () => {
      await backend
        .get(`${API_URL}data/api/deliverycompanies?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => ({
            delivery_company_pk: item.pk,
            delivery_company_name: item.name,
            csv_header_mapping: item.csv_header_mapping,
            default_identifier: item.default_identifier,
          }));
          setDeliveryCompanies(dataMap);
        });
    };

    const getPartnersConfigurations = async () => {
      await backend
        .get(`${API_URL}data/api/deliverycompanyconfigurations?no_page=1`)
        .then(({ data }) => {
          setPartnersConfigurations(data);
        });
    };

    const getPartnersEntities = async () => {
      await backend
        .get(`${API_URL}data/api/partnerentitythroughs?no_page=1`)
        .then(({ data }) => {
          setPartnersEntities(data);
        });
    };

    const getPartnersBrands = async () => {
      await backend
        .get(`${API_URL}data/api/partnerbrandthroughs?no_page=1`)
        .then(({ data }) => {
          setPartnersBrands(data);
        });
    };

    getPartners();
    getBrands();
    getCompanies();
    getEntities();
    getPartnersConfigurations();
    getPartnersEntities();
    getPartnersBrands();
  }, []);

  useEffect(() => {
    if (
      partners.length &&
      brands.length &&
      entities.length &&
      deliveryCompanies.length &&
      partnersConfigurations.length &&
      partnersEntities.length &&
      partnersBrands.length &&
      !partnersRemapped
    ) {
      let partnersNewDict = partners.map((item) => ({
        ...item,
        delivery_companies: partnersConfigurations
          .filter((pcItem) => pcItem.partner === item.pk)
          .map((pcItem) => ({
            delivery_company: pcItem.delivery_company,
            store_id: pcItem.store_id,
            dcid: pcItem.dcid,
          })),
        brands: partnersBrands
          .filter((pbItem) => pbItem.partner_id === item.pk)
          .map((pbItem) => pbItem.brand_id),
        entities: partnersEntities
          .filter((peItem) => peItem.partner_id === item.pk)
          .map((peItem) => peItem.entity_id),
      }));
      setPartners(partnersNewDict);
      setPartnersRemapped(true);
    }
  }, [
    partners,
    brands,
    entities,
    deliveryCompanies,
    partnersConfigurations,
    partnersEntities,
    partnersBrands,
    partnersRemapped,
  ]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <OrdersUpload {...props} handleOrdersData={handleOrdersData} />;
      case 1:
        return (
          <OrdersPreview
            {...props}
            ordersData={ordersData}
            ordersConfig={ordersConfig}
            deliveryCompanies={deliveryCompanies}
            brands={brands}
            entities={entities}
            partners={partners}
            handleOrdersConfig={handleOrdersConfig}
            handleOrdersDataMapped={handleOrdersDataMapped}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const handleCancel = () => {
    props.history.push("/orders");
  };

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      toggleConfirmModal();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      {!loadingFlag && !finishedFlag ? (
        <>
          Are you sure you want to upload all valid orders?
          <div align="right">
            <Button
              onClick={() => toggleConfirmModal()}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => sendRequest()}
              className={classes.button}
            >
              Confirm
            </Button>
          </div>
        </>
      ) : loadingFlag ? (
        <>
          <div align="center">
            <CircularProgress />
            <div>
              Uploading orders <br />
              This may take some time relative to the number of orders.
            </div>
          </div>
          {errorInfo.error ? (
            <>
              <br />
              <br />
              There was an error while uploading the orders
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  wordBreak: "break-word",
                  maxHeight: 160,
                  overflow: "hidden",
                  display: "inline-block",
                }}
              >
                <b>Error:</b>
                <br />
                {errorInfo.message}
              </span>
              <div align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => resolveRequest()}
                  className={classes.button}
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          Valid orders have been successfully added
          <div align="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => resolveRequest()}
              className={classes.button}
            >
              Close
            </Button>
          </div>
        </>
      )}
    </div>
  );

  const resolveRequest = () => {
    setFinishedFlag(false);
    toggleConfirmModal();
    props.history.push("/orders");
  };

  const sendRequest = async () => {
    const ordersObj = ordersDataMapped.map((item) => ({
      ...item,
      delivery_company: ordersConfig.delivery_company,
      brand: ordersConfig.brand,
      entity: ordersConfig.entity,
    }));
    setLoadingFlag(true);
    await backend
      .post(`${API_URL + "data/api/orders/"}`, ordersObj)
      .then((response) => {
        setLoadingFlag(false);
        setFinishedFlag(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
      });
  };

  return (
    <div>
      <>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Add Orders
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Modal open={confirmModal} onClose={toggleConfirmModal}>
              {confirmModalContent}
            </Modal>
            <>
              {activeStep === steps.length ? (
                <></>
              ) : (
                <>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </>
              )}
            </>
          </Paper>
        </main>
      </>
    </div>
  );
};
