import React from "react";
import InfiniteTable from "./InfiniteTable";
import { Link } from "react-router-dom";
import { Container, Button, Typography } from "@mui/material";

const columns = [
  { id: "name", editable: "/deliverycompanies", editablePk: "pk", label: "Delivery Company" },
];

export const DeliveryCompany = () => {
  return (
    <>
      <Container style={{ textAlign: "right" }}>
        <Link
          to="/deliverycompanies/add"
          style={{ textDecoration: "none" }}
          color="secondary"
        >
          <Button variant="contained" color="primary">
            <Typography variant="subtitle2" className="text-white">
              Add Delivery Company
            </Typography>
          </Button>
        </Link>
      </Container>
      <Container>
        <InfiniteTable
          apiep="data/api/deliverycompanies/"
          columns={columns}
          data={null}
          dataMapByColumn="id"
          deletable={false}
          editable={true}
          filterable={true}
          label="Delivery Companies"
          fetchingModal={null}
        />
      </Container>
    </>
  );
};
