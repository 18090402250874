import React, { Fragment, useState, useEffect } from "react";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { backend, API_URL } from "../backend_api";
import { PayoutDates } from "./PayoutDates";
import { PayoutRange } from "./PayoutRange";
import { PayoutCalculate } from "./PayoutCalculate";
import { PayoutRetry } from "./PayoutRetry";
import { PayoutSubscriptions } from "./PayoutSubscriptions";
import { PayoutJournal } from "./PayoutJournal";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      width: 900,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const PayoutProcess = (props) => {
  const classes = useStyles();
  const steps = {
    new: ["Select dates", "Orders", "Order Calculations"],
    retry: ["Select dates", "Payouts"],
    billing: ["Select info", "Subscriptions"],
    sync: ["Select dates", "Orders", "Journals"],
  };
  const dateISO = new Date().toISOString();
  const urlPath = props.match.path;

  const [activeStep, setActiveStep] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [finishedFlag, setFinishedFlag] = useState(false);
  const [payin, setPayin] = useState(false);
  const [sendStatements, setSendStatements] = useState(false);
  const [action, setAction] = useState(
    urlPath === "/payout/add" ? "new" : "sync"
  );
  const [partners, setPartners] = useState([]);
  const [partnersRemapped, setPartnersRemapped] = useState(false);
  const [partnersEntities, setPartnersEntities] = useState([]);
  const [partnersBrands, setPartnersBrands] = useState([]);
  const [entities, setEntities] = useState([]);
  const [entitiesFull, setEntitiesFull] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandsFull, setBrandsFull] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [selectedEntity, setSelectedEntity] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedDatePayout, setSelectedDatePayout] = useState(dateISO);
  const [selectedDateFrom, setSelectedDateFrom] = useState(dateISO);
  const [selectedDateTo, setSelectedDateTo] = useState(dateISO);

  const handleCancel = () => {
    props.history.push("/payout");
  };

  const handleOneOff = () => {
    props.history.push("/oneoffs");
  };

  const handleNext = () => {
    if (activeStep !== steps[action].length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      toggleConfirmModal();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleDatePayoutChange = (date) => {
    setSelectedDatePayout(date.toISOString());
  };
  const handleDateFromChange = (date) => {
    setSelectedDateFrom(date.toISOString());
  };
  const handleDateToChange = (date) => {
    setSelectedDateTo(date.toISOString());
  };
  const handlePartnerChange = (e) => {
    const { value } = e.target;
    setSelectedPartner(value);
    let partnerObj = partners.find((partner) => partner.pk === value);
    setEntities(
      partnerObj
        ? entitiesFull.filter((entity) =>
            partnerObj.entities.includes(entity.pk)
          )
        : entitiesFull
    );
    setBrands(
      partnerObj
        ? brandsFull.filter((brand) => partnerObj.brands.includes(brand.pk))
        : brandsFull
    );
  };
  const handleEntityChange = (e) => {
    const { value } = e.target;
    setSelectedEntity(value);
    setBrands(brandsFull.filter((brand) => brand.entity === value));
  };
  const handleBrandChange = (e) => {
    const { value } = e.target;
    setSelectedBrand(value);
  };
  const handlePayinChange = (e) => {
    const { checked } = e.target;
    setPayin(checked);
  };
  const handleSendStatementsChange = (e) => {
    const { checked } = e.target;
    setSendStatements(checked);
  };
  const handleActionChange = (type) => (e) => {
    if (action !== type) {
      if (type === "retry" && selectedBrand !== "") {
        let brand = "";
        setSelectedBrand(brand);
      }
      setAction(type);
    }
  };

  useEffect(() => {
    const getPartners = async () => {
      await backend
        .get(`${API_URL}data/api/partners?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => {
            return {
              pk: item.pk,
              name: item.name,
            };
          });
          setPartners(dataMap);
        });
    };

    const getEntities = async () => {
      await backend
        .get(`${API_URL}data/api/entities?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => ({
            pk: item.pk,
            name: item.name,
          }));
          setEntities(dataMap);
          setEntitiesFull(dataMap);
        });
    };

    const getBrands = async () => {
      await backend
        .get(`${API_URL}data/api/brands?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => ({
            pk: item.pk,
            name: item.name,
            entity: item.entity,
          }));
          setBrands(dataMap);
          setBrandsFull(dataMap);
        });
    };

    const getPartnersEntities = async () => {
      await backend
        .get(`${API_URL}data/api/partnerentitythroughs?no_page=1`)
        .then(({ data }) => {
          setPartnersEntities(data);
        });
    };

    const getPartnersBrands = async () => {
      await backend
        .get(`${API_URL}data/api/partnerbrandthroughs?no_page=1`)
        .then(({ data }) => {
          setPartnersBrands(data);
        });
    };

    getPartners();
    getEntities();
    getBrands();
    getPartnersEntities();
    getPartnersBrands();
  }, []);

  useEffect(() => {
    if (
      partners.length &&
      brands.length &&
      entities.length &&
      partnersEntities.length &&
      partnersBrands.length &&
      !partnersRemapped
    ) {
      let partnersNewDict = partners.map((item) => ({
        ...item,
        brands: partnersBrands
          .filter((pbItem) => pbItem.partner_id === item.pk)
          .map((pbItem) => pbItem.brand_id),
        entities: partnersEntities
          .filter((peItem) => peItem.partner_id === item.pk)
          .map((peItem) => peItem.entity_id),
      }));
      setPartners(partnersNewDict);
      setPartnersRemapped(true);
    }
  }, [
    partners,
    brands,
    entities,
    partnersEntities,
    partnersBrands,
    partnersRemapped,
  ]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <PayoutDates
            {...props}
            action={action}
            partners={partners}
            entities={entities}
            brands={brands}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
            handleDatePayoutChange={handleDatePayoutChange}
            handleEntityChange={handleEntityChange}
            handleBrandChange={handleBrandChange}
            handlePartnerChange={handlePartnerChange}
            handlePayinChange={handlePayinChange}
            handleSendStatementsChange={handleSendStatementsChange}
            selectedDateFrom={selectedDateFrom}
            selectedDateTo={selectedDateTo}
            selectedDatePayout={selectedDatePayout}
            selectedEntity={selectedEntity}
            selectedBrand={selectedBrand}
            selectedPartner={selectedPartner}
            payin={payin}
            sendStatements={sendStatements}
          />
        );
      case 1:
        return ["new", "sync"].includes(action) ? (
          <PayoutRange
            {...props}
            selectedDateFrom={selectedDateFrom}
            selectedDateTo={selectedDateTo}
            selectedDatePayout={selectedDatePayout}
            selectedPartner={selectedPartner}
            selectedEntity={selectedEntity}
            selectedBrand={selectedBrand}
            payin={payin}
          />
        ) : action === "retry" ? (
          <PayoutRetry
            {...props}
            selectedDateFrom={selectedDateFrom}
            selectedDateTo={selectedDateTo}
            selectedPartner={selectedPartner}
            selectedEntity={selectedEntity}
            payin={payin}
          />
        ) : (
          <PayoutSubscriptions
            {...props}
            selectedPartner={selectedPartner}
            selectedEntity={selectedEntity}
            selectedBrand={selectedBrand}
          />
        );
      case 2:
        return action === "new" ? (
          <PayoutCalculate
            {...props}
            selectedDateFrom={selectedDateFrom}
            selectedDateTo={selectedDateTo}
            selectedDatePayout={selectedDatePayout}
            selectedPartner={selectedPartner}
            selectedEntity={selectedEntity}
            selectedBrand={selectedBrand}
            payin={payin}
          />
        ) : (
          <PayoutJournal
            {...props}
            selectedDateFrom={selectedDateFrom}
            selectedDateTo={selectedDateTo}
            selectedDatePayout={selectedDatePayout}
            selectedPartner={selectedPartner}
            selectedEntity={selectedEntity}
            selectedBrand={selectedBrand}
            payin={payin}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      {!loadingFlag && !finishedFlag ? (
        <>
          Are you sure you want to send the
          {action !== "billing" ? " payouts" : " subscriptions"} to
          <b> Stripe</b>
          <div align="right">
            <Button
              onClick={() => toggleConfirmModal()}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => sendRequest()}
              className={classes.button}
            >
              Confirm
            </Button>
          </div>
        </>
      ) : loadingFlag ? (
        <>
          <div align="center">
            <CircularProgress />
            <div>
              Sending Payouts <br />
              This may take some time relative to the number of payouts.
            </div>
          </div>
          {errorInfo.error ? (
            <>
              <br />
              <br />
              There was an error while making the payouts
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  wordBreak: "break-word",
                  maxHeight: 160,
                  overflow: "hidden",
                  display: "inline-block",
                }}
              >
                <b>Error:</b>
                <br />
                {errorInfo.message}
              </span>
              <div align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => resolveRequest()}
                  className={classes.button}
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          Payouts sent
          {sendStatements ? (
            <>
              <br />
              <br />
              Statements sent
            </>
          ) : (
            <></>
          )}
          <div align="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => resolveRequest()}
              className={classes.button}
            >
              Close
            </Button>
          </div>
        </>
      )}
    </div>
  );

  const resolveRequest = () => {
    setFinishedFlag(false);
    toggleConfirmModal();
    props.history.push("/payout");
  };

  const sendRequest = async () => {
    setLoadingFlag(true);
    let partnerParam =
      selectedPartner !== "" ? "&partner=" + selectedPartner : "";
    let entityParam = selectedEntity !== "" ? "&entity=" + selectedEntity : "";
    let brandParam = selectedBrand !== "" ? "&brand=" + selectedBrand : "";
    let payinParam = payin ? "&pay_in=" + payin : "";
    let endpointBuilt =
      action === "new"
        ? `calculatepayouts/?from_date=${selectedDateFrom}&to_date=${selectedDateTo}&payout_date=${selectedDatePayout}&no_page=1${partnerParam}${entityParam}${brandParam}${payinParam}`
        : action === "retry"
        ? `retrypayouts/?from_date=${selectedDateFrom}&to_date=${selectedDateTo}&no_page=1${partnerParam}${entityParam}${payinParam}`
        : `subscriptions/?no_page=1${partnerParam}${entityParam}${brandParam}${payinParam}`;

    if (action !== "billing") {
      await backend
        .post(`${API_URL}data/api/${endpointBuilt}`)
        .then((response) => {
          let payoutPks = response.data.length ? response.data : [];
          let payoutPksSS = {
            payout_pk_list: payoutPks.map((item) => item.pk),
          };
          setLoadingFlag(false);
          if (sendStatements) {
            sendStatementsRequest(payoutPksSS);
          }
          setFinishedFlag(true);
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
        });
    } else {
      await backend
        .get(`${API_URL}data/api/${endpointBuilt}`)
        .then(({ data }) => {
          let payoutDate = new Date().toISOString();
          let subscriptionsPayload = [];
          data.forEach((subscription) => {
            let partnerObj = partners.find(
              (item) => item.pk === subscription.partner
            );
            if (partnerObj.hasOwnProperty("pk")) {
              let subscriptionObj = {
                partner: subscription.partner,
                entity: partnerObj.entities[0],
                raas_fees: subscription.flat_amount,
                description: subscription.description,
                payout_date: payoutDate,
              };
              subscriptionsPayload.push(subscriptionObj);
            }
          });
          sendBillingRequest(subscriptionsPayload);
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
        });
    }
  };

  const sendBillingRequest = async (subscriptionsPayload) => {
    await backend
      .post(
        `${API_URL}data/api/nonorderpayouts/?pay_in=true`,
        subscriptionsPayload
      )
      .then(({ data }) => {
        let payoutPks = data.length ? data : [];
        let payoutPksSS = {
          payout_pk_list: payoutPks.map((item) => item.pk),
        };
        setLoadingFlag(false);
        if (sendStatements) {
          sendStatementsRequest(payoutPksSS, true);
        }
        setFinishedFlag(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
      });
  };

  const sendStatementsRequest = async (payoutPks, nonorder = false) => {
    const extraParams = nonorder ? "&payout_type=nonorder&pay_in=true" : "";
    await backend
      .post(
        `${API_URL}data/api/generatestatements/?email_out=true${extraParams}`,
        payoutPks
      )
      .then((response) => {
        setLoadingFlag(false);
        setFinishedFlag(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
      });
  };

  return (
    <div>
      <Fragment>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {activeStep === 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{ marginBottom: 10 }}
                  variant="contained"
                  color={action === "new" ? "primary" : "inherit"}
                  onClick={handleActionChange("new")}
                  className={classes.button}
                >
                  New Payout
                </Button>
                <Button
                  style={{ marginBottom: 10 }}
                  variant="contained"
                  color={action === "retry" ? "primary" : "inherit"}
                  onClick={handleActionChange("retry")}
                  className={classes.button}
                >
                  Retry Payout
                </Button>
                <Button
                  style={{ marginBottom: 10 }}
                  variant="contained"
                  color={action === "billing" ? "primary" : "inherit"}
                  onClick={handleActionChange("billing")}
                  className={classes.button}
                >
                  Billing
                </Button>
                <Button
                  style={{ marginBottom: 10 }}
                  variant="contained"
                  color={action === "sync" ? "primary" : "inherit"}
                  onClick={handleActionChange("sync")}
                  className={classes.button}
                >
                  Sync Order
                </Button>
                <Button
                  style={{ marginBottom: 10 }}
                  variant="contained"
                  onClick={handleOneOff}
                  className={classes.button}
                >
                  One-Off
                </Button>
              </div>
            ) : (
              <></>
            )}
            <Typography component="h1" variant="h4" align="center">
              {
                {
                  new: "New Payout",
                  retry: "Retry Payout",
                  billing: "Billing",
                  sync: "Sync Orders",
                }[action]
              }
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps[action].map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Modal open={confirmModal} onClose={toggleConfirmModal}>
              {confirmModalContent}
            </Modal>
            <Fragment>
              {activeStep === steps[action].length ? (
                <Fragment></Fragment>
              ) : (
                <Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps[action].length - 1
                        ? "Finish"
                        : "Next"}
                    </Button>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </Paper>
        </main>
      </Fragment>
    </div>
  );
};
