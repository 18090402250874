import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  TextField,
  CssBaseline,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { backend, API_URL } from "../backend_api";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

export const OrdersForm = (props) => {
  const classes = useStyles();

  const [orderSelected, setOrderSelected] = useState({
    oid: "",
    delivery_company_name: "",
    partner_name: "",
    workflow_id: "",
    dining_mode: "",
    promotion_food: "",
    promotion_food_tax: "",
    promotion_delivery: "",
    promotion_delivery_tax: "",
    bag_fee: "",
    dispatch_fee: "",
    dispatch_fee_tax: "",
    gratuity: "",
    misc_payment_description: "",
    misc_payment: "",
    marketplace_facilitator_tax: "",
    backup_withholding_tax: "",
    stated_payout_date: "",
    stated_payout: "",
    raas_fees: "",
  });

  useEffect(() => {
    const orderGet = async (props) => {
      await backend
        .get(`${API_URL}data/api/orders/${props.match.params.id}`)
        .then((response) => {
          setOrderSelected({
            oid: response.data.oid || "",
            delivery_company_name: response.data.delivery_company_name || "",
            partner_name: response.data.partner_name || "",
            workflow_id: response.data.workflow_id || "",
            dining_mode: response.data.dining_mode || "",
            promotion_food: response.data.promotion_food || 0,
            promotion_food_tax: response.data.promotion_food_tax || 0,
            promotion_delivery: response.data.promotion_delivery || 0,
            promotion_delivery_tax: response.data.promotion_delivery_tax,
            bag_fee: response.data.bag_fee || 0,
            dispatch_fee: response.data.dispatch_fee || 0,
            dispatch_fee_tax: response.data.dispatch_fee_tax || 0,
            gratuity: response.data.gratuity || 0,
            misc_payment_description:
              response.data.misc_payment_description || "",
            misc_payment: response.data.misc_payment || 0,
            marketplace_facilitator_tax:
              response.data.marketplace_facilitator_tax || 0,
            backup_withholding_tax: response.data.backup_withholding_tax || 0,
            stated_payout_date: response.data.stated_payout_date || "",
            stated_payout: response.data.stated_payout || 0,
            raas_fees: response.data.raas_fees || 0,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (props.match.params.id) {
      orderGet(props);
    }
  }, [props]);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography color="primary" component="h4">
            Orders Details
          </Typography>
          <form
            className={classes.form}
            margin="dense"
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Oid: "
                  name="oid"
                  value={orderSelected && orderSelected.oid}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Delivery Company: "
                  name="delivery_company_name"
                  value={orderSelected && orderSelected.delivery_company_name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Partner: "
                  name="partner_name"
                  value={orderSelected && orderSelected.partner_name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Workflow ID: "
                  name="workflow_id"
                  value={orderSelected && orderSelected.workflow_id}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Dining mode: "
                  name="dining_mopde"
                  value={orderSelected && orderSelected.dining_mode}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Promotion food: "
                  name="promotion_food"
                  value={orderSelected && orderSelected.promotion_food}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Promotion food tax: "
                  name="promotion_food_tax"
                  value={orderSelected && orderSelected.promotion_food_tax}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Promotion delivery: "
                  name="promotion_delivery"
                  value={orderSelected && orderSelected.promotion_delivery}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Promotion delivery tax: "
                  name="promotion_delivery_tax"
                  value={orderSelected && orderSelected.promotion_delivery_tax}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Bag fee: "
                  name="bag_fee"
                  value={orderSelected && orderSelected.bag_fee}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Dispatch fee: "
                  name="dispatch_fee"
                  value={orderSelected && orderSelected.dispatch_fee}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Dispatch fee tax: "
                  name="dispatch_fee_tax"
                  value={orderSelected && orderSelected.dispatch_fee_tax}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Gratuity: "
                  name="gratuity"
                  value={orderSelected && orderSelected.gratuity}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Misc Payment description: "
                  name="misc_payment_description"
                  value={
                    orderSelected && orderSelected.misc_payment_description
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Misic payment: "
                  name="misc_payment"
                  value={orderSelected && orderSelected.misc_payment}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Market place facilitator tac: "
                  name="marketplace_facilitator_tax"
                  value={
                    orderSelected && orderSelected.marketplace_facilitator_tax
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="With holding tax: "
                  name="backup_withholding_tax"
                  value={orderSelected && orderSelected.backup_withholding_tax}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="State payout data: "
                  name="stated_payout_date"
                  value={orderSelected && orderSelected.stated_payout_date}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="State payout : "
                  name="stated_payout"
                  value={orderSelected && orderSelected.stated_payout}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Raas fees: "
                  name="raas_fees"
                  value={orderSelected && orderSelected.raas_fees}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
};
