import React from "react";

import { Container } from "@mui/material";

import DashboardOrders from "./DashboardOrders";
// import D3Graph from "./D3Graph";
// import DashboardGraphs from "./DashboardGraphs";
// import { CarouselContainer } from "./CarouselContainer";
// import { ScrollableContainer } from "./ScrollableContainer";

export const Dashboard = () => {
  /* const categoricalColors = [
    "#00c0c7",
    "#5144d3",
    "#e8871a",
    "#da3490",
    "#9089fa",
    "#47e26f",
    "#2780eb",
    "#6f38b1",
    "#dfbf03",
    "#cb6f10",
    "#268d6c",
    "#9bec54",
  ]; */
  return (
    <>
      {/* <Container className="graphs-container">
        <DashboardGraphs />
      </Container> */}
      {/* <CarouselContainer
        width="1165px"
        margin={{ top: 0, right: 0, bottom: 10, left: 0 }}
      >
        <div>
          <D3Graph
            type="bar"
            apiep="data/api/payouts"
            data={null}
            width={552}
            height={654}
            margin={{ top: 40, right: 40, bottom: 40, left: 150 }}
            floatable="left"
            scrollable={false}
            labelx="Total"
            labely={null}
            title="Top 25 Total Payouts / Partner"
            color={categoricalColors[10]}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 10,
              maxWidth: 592,
              paddingLeft: 10,
            }}
          >
            <D3Graph
              type="scatter"
              apiep="data/api/payouts"
              data={null}
              width={552}
              height={300}
              margin={{ top: 40, right: 40, bottom: 40, left: 60 }}
              floatable="none"
              scrollable={false}
              labelx="Total"
              labely="Total + 100"
              title="Total Payouts / Partner"
              color={categoricalColors[10]}
            />
            <D3Graph
              type="line"
              apiep="data/api/payouts"
              data={null}
              width={552}
              height={300}
              margin={{ top: 40, right: 40, bottom: 40, left: 60 }}
              floatable="none"
              scrollable={false}
              labelx="Total"
              labely="Total + 100"
              title="First 10 Partners Total Payouts"
              color={categoricalColors[10]}
            />
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              gap: "10px 10px",
              maxWidth: 592,
              flexDirection: "column",
              alignItems: "flex-start",
              float: "left",
              marginRight: 10,
            }}
          >
            <D3Graph
              type="scatter"
              apiep="data/api/payouts"
              data={null}
              width={557}
              height={300}
              margin={{ top: 40, right: 40, bottom: 40, left: 60 }}
              floatable="none"
              scrollable={false}
              labelx="Total"
              labely="Total + 100"
              title="Total Payouts / Partner"
              color={categoricalColors[10]}
            />
            <D3Graph
              type="line"
              apiep="data/api/payouts"
              data={null}
              width={557}
              height={300}
              margin={{ top: 40, right: 40, bottom: 40, left: 60 }}
              scrollable={false}
              floatable="none"
              labelx="Total"
              labely="Total + 100"
              title="First 10 Partners Total Payouts"
              color={categoricalColors[10]}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px 10px",
              maxWidth: 592,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <D3Graph
              type="mapl"
              apiep="data/api/partners"
              data={null}
              width={557}
              height={300}
              margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
              floatable="left"
              scrollable={false}
              labelx={null}
              labely={null}
              title="Partners / State"
              color={categoricalColors[10]}
            />
            <D3Graph
              type="pie"
              apiep="data/api/orders"
              data={null}
              width={557}
              height={300}
              margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
              floatable="left"
              scrollable={false}
              labelx={"Example"}
              labely={null}
              title="Orders / Delivery Company"
              color={categoricalColors[10]}
              innerRadius={0}
            />
          </div>
        </div>
        <D3Graph
          type="bar"
          apiep="data/api/payouts"
          data={null}
          width={552}
          height={654}
          margin={{ top: 40, right: 40, bottom: 40, left: 150 }}
          floatable="none"
          scrollable={false}
          labelx="Total"
          labely={null}
          title="Top 25 Total Payouts / Partner"
          color={categoricalColors[10]}
        />
        <div
          style={{
            display: "flex",
            gap: "10px 10px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <D3Graph
            type="scatter"
            apiep="data/api/payouts"
            data={null}
            width={552}
            height={300}
            margin={{ top: 40, right: 40, bottom: 40, left: 60 }}
            floatable="none"
            scrollable={false}
            labelx="Total"
            labely="Total + 100"
            title="Total Payouts / Partner"
            color={categoricalColors[10]}
          />
          <D3Graph
            type="line"
            apiep="data/api/payouts"
            data={null}
            width={552}
            height={300}
            margin={{ top: 40, right: 40, bottom: 40, left: 60 }}
            scrollable={false}
            floatable="none"
            labelx="Total"
            labely="Total + 100"
            title="First 10 Partners Total Payouts"
            color={categoricalColors[10]}
          />
        </div>
        <div
          style={{ display: "flex", gap: "10px 10px", flexDirection: "column", alignItems: "center" }}
        >
          <D3Graph
            type="mapl"
            apiep="data/api/partners"
            data={null}
            width={552}
            height={300}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            floatable="none"
            scrollable={false}
            labelx={null}
            labely={null}
            title="Partners / State"
            color={categoricalColors[10]}
          />
          <D3Graph
            type="pie"
            apiep="data/api/orders"
            data={null}
            width={552}
            height={300}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            floatable="none"
            scrollable={false}
            labelx={"Example"}
            labely={null}
            title="Orders / Delivery Company"
            color={categoricalColors[10]}
            innerRadius={0}
          />
        </div>
      </CarouselContainer>
      <ScrollableContainer
        width="auto"
        height={699}
        margin={{ top: 0, right: 24, bottom: 10, left: 24 }}
        title={null}
        scrollDirection="horizontal"
      >
        <div style={{ display: "flex", gap: "10px 10px" }}>
          <D3Graph
            type="bar"
            apiep="data/api/payouts"
            data={null}
            width={552}
            height={654}
            margin={{ top: 40, right: 40, bottom: 40, left: 150 }}
            floatable="none"
            scrollable={false}
            labelx="Total"
            labely={null}
            title="Top 25 Total Payouts / Partner"
            color={categoricalColors[10]}
          />
          <div
            style={{
              display: "flex",
              gap: "10px 10px",
              flexDirection: "column",
            }}
          >
            <D3Graph
              type="scatter"
              apiep="data/api/payouts"
              data={null}
              width={552}
              height={300}
              margin={{ top: 40, right: 40, bottom: 40, left: 60 }}
            floatable="none"
              scrollable={false}
              labelx="Total"
              labely="Total + 100"
              title="Total Payouts / Partner"
              color={categoricalColors[10]}
            />
            <D3Graph
              type="line"
              apiep="data/api/payouts"
              data={null}
              width={552}
              height={300}
              ar
              margin={{ top: 40, right: 40, bottom: 40, left: 60 }}
            floatable="none"
              scrollable={false}
              labelx="Total"
              labely="Total + 100"
              title="First 10 Partners Total Payouts"
              color={categoricalColors[10]}
            />
          </div>
          <D3Graph
            type="mapl"
            apiep="data/api/partners"
            data={null}
            width={552}
            height={300}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            floatable="none"
            scrollable={false}
            labelx={null}
            labely={null}
            title="Partners / State"
            color={categoricalColors[10]}
          />
        </div>
      </ScrollableContainer>
      <div style={{ margin: "0px 24px 10px 24px" }}>
        <D3Graph
          type="bar"
          apiep="data/api/payouts"
          data={null}
          width={552}
          height={654}
          margin={{ top: 40, right: 40, bottom: 40, left: 150 }}
          floatable="none"
          scrollable={false}
          labelx="Total"
          labely={null}
          title="Top 25 Total Payouts / Partner"
          color={categoricalColors[10]}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: 10,
            maxWidth: 582,
            paddingLeft: 10,
          }}
        >
          <D3Graph
            type="scatter"
            apiep="data/api/payouts"
            data={null}
            width={552}
            height={300}
            margin={{ top: 40, right: 40, bottom: 40, left: 60 }}
            floatable="none"
            scrollable={false}
            labelx="Total"
            labely="Total + 100"
            title="Total Payouts / Partner"
            color={categoricalColors[10]}
          />
          <D3Graph
            type="line"
            apiep="data/api/payouts"
            data={null}
            width={552}
            height={300}
            margin={{ top: 40, right: 40, bottom: 40, left: 60 }}
            floatable="none"
            scrollable={false}
            labelx="Total"
            labely="Total + 100"
            title="First 10 Partners Total Payouts"
            color={categoricalColors[10]}
          />
        </div>
      </div> */}
      <Container className="dashboard-orders-container">
        <DashboardOrders />
      </Container>
    </>
  );
};
