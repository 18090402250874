import React from "react";
import { Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CSVReader } from "react-papaparse";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export const DeliveryUpload = (props) => {
  const classes = useStyles();
  const { companySelected } = props;

  const handleOnDrop = (data) => {
    const headersData = data[0].data;
    companySelected.headers = headersData;
  };

  return (
    <div className={classes.root}>
      <Typography color="primary" component="h4">
        Upload the headers for your delivery company
      </Typography>

      <Grid item xs={12}>

        <CSVReader onDrop={handleOnDrop} noDrop></CSVReader>
      </Grid>
    </div>
  );
};
