import React, { useState, useEffect } from "react";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { backend, API_URL } from "../backend_api";
import { PartnerFormConfigurations } from "./PartnerFormConfigurations";
import { PartnerForm } from "./PartnerForm";
import { PartnerExtraFees } from "./PartnerExtraFees";
import { PartnerSubscriptions } from "./PartnerSubscriptions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const PartnerProcess = (props) => {
  const classes = useStyles();
  const steps = [
    "Partner Information",
    "Delivery Company Configurations",
    "Extra Fees",
    "Subscriptions",
  ];
  const partnerParamId = props.match.params.id;

  const [activeStep, setActiveStep] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });
  const [entities, setEntities] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandsFull, setBrandsFull] = useState([]);
  const [deliveryCompanies, setDeliveryCompanies] = useState([]);
  const [stripe, setStripe] = useState([]);
  const [equation, setEquation] = useState([]);
  const [structure, setStructure] = useState([]);
  const [feesStructure, setFeesStructure] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsFull, setSubscriptionsFull] = useState([]);
  const [partnerSelected, setPartnerSelected] = useState({
    pk: "",
    raas_location_id: "",
    name: "",
    external_name: "",
    address: "",
    primary_email: "",
    secondary_email_addresses: "",
    delivery_companies: [],
    entities: [],
    brands: [],
    stripe: "",
    stripe_customer_id: "",
    invite_stripe: false,
    invite_stripe_customer_id: false,
  });
  const [partnerSelectedConfig, setPartnerSelectedConfig] = useState([]);
  const [partnerSelectedExtraConfig, setPartnerSelectedExtraConfig] = useState(
    []
  );
  const [partnerExtraFee, setPartnerExtraFee] = useState({
    structure_type: "",
    delivery_company: "",
    base_percentage: "",
    floor_percentage: "",
    recovered_amount: "",
    flat_amount: "",
    valid: false,
    constant: false,
    start_date: new Date().toISOString(),
    end_date: new Date().toISOString(),
  });
  const [partnerSelectedSubscription, setPartnerSelectedSubscription] =
    useState([]);
  const [partnerExtraSubscription, setPartnerExtraSubscription] = useState({
    description: "",
    flat_amount: "",
  });
  const [partnerLoaded, setPartnerLoaded] = useState(false);

  const handleChange = (e) => {
    setPartnerSelected((prevState) => {
      const { name, value, checked } = e.target;
      let partner = {};
      switch (name) {
        case "raas_location_id":
          partner = {
            ...prevState,
            [name]: value,
            pk: value,
          };
          break;
        case "invite_stripe":
          partner = {
            ...prevState,
            [name]: checked,
          };
          break;
        case "invite_stripe_customer_id":
          partner = {
            ...prevState,
            [name]: checked,
          };
          break;
        default:
          partner = {
            ...prevState,
            [name]: value,
          };
      }

      return partner;
    });
  };

  const handleChangeConfiguration = (idx) => (e) => {
    const { name, value } = e.target;
    setPartnerSelectedConfig((prevState) => {
      return prevState.map((config, index) => {
        if (idx !== index) return config;
        return { ...config, [name]: value };
      });
    });
  };

  const handleSuppressDateFromChange = (idx) => (date) => {
    setPartnerSelectedConfig((prevState) => {
      return prevState.map((config, index) => {
        if (idx !== index) return config;
        return { ...config, start_date: date.toISOString() };
      });
    });
  };

  const handleSuppressDateToChange = (idx) => (date) => {
    setPartnerSelectedConfig((prevState) => {
      return prevState.map((config, index) => {
        if (idx !== index) return config;
        return { ...config, end_date: date.toISOString() };
      });
    });
  };

  const handleChangeExtraConfiguration =
    (idx, evname = null) =>
    (e) => {
      let name = null;
      let value = null;
      if (evname === null) {
        name = e.target.name.replace("-" + idx, "");
        value =
          name.includes("valid") || name.includes("constant")
            ? e.target.checked
            : e.target.value;
      } else {
        name = evname;
        value = e.toISOString();
      }

      setPartnerSelectedExtraConfig((prevState) => {
        return prevState.map((fee, index) => {
          if (idx !== index) return fee;
          return { ...fee, [name]: value };
        });
      });
    };

  const handleChangeExtraFee = (e) => {
    const { name, value, checked } = e.target;
    setPartnerExtraFee((prevState) => ({
      ...prevState,
      [name]:
        name.includes("valid") || name.includes("constant")
          ? checked
          : name.includes("_date")
          ? value.toISOString()
          : value,
    }));
  };

  const handleExtraFeeAddChange = () => {
    const delivery_company = partnerExtraFee.delivery_company;

    setPartnerSelected((prevState) => ({
      ...prevState,
      delivery_companies: prevState.delivery_companies.map((item) => {
        if (item.delivery_company !== delivery_company) return item;
        return {
          ...item,
          fees: [...item.fees, partnerExtraFee],
        };
      }),
    }));

    setPartnerSelectedExtraConfig((prevState) => [
      ...prevState,
      partnerExtraFee,
    ]);

    setPartnerExtraFee({
      structure_type: "",
      delivery_company: "",
      base_percentage: "",
      floor_percentage: "",
      recovered_amount: "",
      flat_amount: "",
      valid: false,
      constant: false,
      start_date: new Date().toISOString(),
      end_date: new Date().toISOString(),
    });
  };

  const handleChangeSubscription = (idx) => (e) => {
    const name = e.target.name.replace("-" + idx, "");
    const value = e.target.value;

    setPartnerSelectedSubscription((prevState) => {
      return prevState.map((subscription, index) => {
        if (idx !== index) return subscription;
        return { ...subscription, [name]: value };
      });
    });
  };

  const handleChangeSubscriptionDelete = (idx, item) => async (e) => {
    if (item.pk) {
      await backend
        .delete(`${API_URL}data/api/subscriptions/${item.pk}`)
        .then((response) => {
          setPartnerSelectedSubscription((prevState) =>
            prevState.filter((subscription, index) => index !== idx)
          );
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
        });
    } else {
      setPartnerSelectedSubscription((prevState) =>
        prevState.filter((subscription, index) => index !== idx)
      );
    }
  };

  const handleChangeExtraSubscription = (e) => {
    const { name, value } = e.target;
    setPartnerExtraSubscription((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeExtraSubscriptionAdd = () => {
    setPartnerSelectedSubscription((prevState) => [
      ...prevState,
      { ...partnerExtraSubscription, partner: partnerParamId },
    ]);

    setPartnerExtraSubscription({ description: "", flat_amount: "" });
  };

  useEffect(() => {
    const subscriptionsGet = async () => {
      await backend
        .get(`${API_URL}data/api/subscriptions?no_page=1`)
        .then(({ data }) => {
          setSubscriptions(data);
          setSubscriptionsFull(data);
        });
    };

    const entitiesGet = async () => {
      await backend
        .get(`${API_URL}data/api/entities?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => ({
            pk: item.pk,
            name: item.name,
          }));
          setEntities(dataMap);
        });
    };

    const brandsGet = async () => {
      await backend
        .get(`${API_URL}data/api/brands?no_page=1`)
        .then(({ data }) => {
          const dataMap = data.map((item) => ({
            pk: item.pk,
            name: item.name,
            entity: item.entity,
          }));
          setBrands(dataMap);
          setBrandsFull(dataMap);
        });
    };

    const companiesGet = async () => {
      await backend
        .get(`${API_URL}data/api/deliverycompanies`)
        .then(({ data }) => {
          const dataMap = data.results.map((item) => ({
            pk: "",
            delivery_company: item.pk,
            delivery_company_name: item.name,
            dcid: "",
            store_id: "",
            fees: [],
            start_date: new Date().toISOString(),
            end_date: new Date().toISOString(),
          }));
          setDeliveryCompanies(dataMap);
        });
    };

    const stripeGet = async () => {
      await backend
        .get(`${API_URL}data/api/stripesetups?no_page=1`)
        .then(({ data }) => {
          const stripeMap = data.map((item) => ({
            pk: item.pk,
            stripe_account_id: item.stripe_account_id,
            bank_account: item.bank_account,
            email: item.email,
          }));
          setStripe(stripeMap);
        });
    };

    const equationGet = async () => {
      await backend.get(`${API_URL}data/api/equations`).then(({ data }) => {
        const equationMap = data.results.map((item) => ({
          pk: item.pk,
          name: item.name,
        }));

        setEquation(equationMap);
      });
    };

    const structureGet = async () => {
      await backend
        .get(`${API_URL}data/api/structuressupported`)
        .then(({ data }) => {
          const structureMap = data
            .filter((item) => !["extra_fee"].includes(item))
            .map((item) => item);

          setStructure(structureMap);
        });
    };

    const feesStructureGet = async () => {
      await backend
        .get(`${API_URL}data/api/feestructuressupported`)
        .then(({ data }) => {
          const feesStructureMap = data.map((item) => item);

          setFeesStructure(feesStructureMap);
        });
    };

    subscriptionsGet();
    entitiesGet();
    brandsGet();
    companiesGet();
    equationGet();
    stripeGet();
    structureGet();
    feesStructureGet();
  }, []);

  useEffect(() => {
    const partnerGet = async () => {
      await backend
        .get(`${API_URL}data/api/partners/${partnerParamId}`)
        .then((response) => {
          setPartnerLoaded(true);
          let partnerObj = {
            pk: response.data.raas_location_id || "",
            raas_location_id: response.data.raas_location_id || "",
            name: response.data.name || "",
            external_name: response.data.external_name || "",
            address: response.data.address || "",
            primary_email: response.data.primary_email || "",
            secondary_email_addresses:
              response.data.secondary_email_addresses || "",
            delivery_companies: response.data.delivery_companies || [],
            entities: response.data.entities || [],
            brands: response.data.brands || [],
            stripe: response.data.stripe || "",
            stripe_customer_id: response.data.stripe_customer_id || "",
          };

          partnerObj.delivery_companies = partnerObj.delivery_companies.map(
            (delivery) => ({
              ...delivery,
              start_date:
                delivery.start_date === null
                  ? new Date().toISOString()
                  : new Date(`${delivery.start_date} 00:00:00`).toISOString(),
              end_date:
                delivery.end_date === null
                  ? new Date().toISOString()
                  : new Date(`${delivery.end_date} 00:00:00`).toISOString(),
              fees: delivery.fees.map((fee) => ({
                ...fee,
                delivery_company: delivery.delivery_company,
                start_date: new Date(
                  `${fee.start_date} 00:00:00`
                ).toISOString(),
                end_date: new Date(`${fee.end_date} 00:00:00`).toISOString(),
              })),
            })
          );

          let partnerEntitiesPk = [].concat(partnerObj.entities);

          partnerObj.entities = partnerObj.entities.map((entity) => {
            let entityObj = entities.find((item) => item.pk === entity);
            return { pk: entityObj.pk, name: entityObj.name };
          });

          partnerObj.brands = partnerObj.brands.map((brand) => {
            let brandObj = brandsFull.find(
              (item) =>
                item.pk === brand && partnerEntitiesPk.includes(item.entity)
            );
            return {
              pk: brandObj.pk,
              name: brandObj.name,
              entity: brandObj.entity,
            };
          });

          let fees = [];
          partnerObj.delivery_companies.forEach(
            (item) => (fees = [...fees, ...item.fees])
          );
          
          let partnerSubscriptions = subscriptionsFull.filter(
            (item) => item.partner === +partnerParamId
          );

          if (partnerParamId) {
            partnerObj = { ...partnerObj, pk: response.data.pk };
          }
          setPartnerSelected(partnerObj);
          setPartnerSelectedConfig(partnerObj.delivery_companies);
          setPartnerSelectedExtraConfig(fees);
          setPartnerSelectedSubscription(partnerSubscriptions);
          setSubscriptions(partnerSubscriptions);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (
      !isNaN(partnerParamId) &&
      brandsFull.length > 0 &&
      entities.length > 0 &&
      !partnerLoaded
    ) {
      partnerGet();
    }
  }, [
    brands,
    brandsFull,
    entities,
    subscriptionsFull,
    partnerLoaded,
    partnerParamId,
  ]);

  useEffect(() => {
    const partnerEntitiesPk = partnerSelected.entities.map(
      (entity) => entity.pk
    );
    const filterEntities = () => {
      setEntities((prevState) =>
        prevState.filter(
          (item) =>
            !partnerSelected.entities.some((entity) => entity.pk === item.pk)
        )
      );
    };

    const filterBrands = () => {
      setBrands(
        brandsFull.filter(
          (item) =>
            partnerEntitiesPk.includes(item.entity) &&
            !partnerSelected.brands.some((brand) => brand.pk === item.pk)
        )
      );
    };

    const partnerDeliveryCompanies = partnerSelected.delivery_companies.map(
      (item) => item.delivery_company
    );

    const filterDeliveryCompanies = () => {
      setDeliveryCompanies((prevState) =>
        prevState.filter(
          (item) => !partnerDeliveryCompanies.includes(item.delivery_company)
        )
      );
    };

    setPartnerSelectedConfig((prevState) =>
      [].concat(
        prevState,
        prevState.length > 0
          ? partnerSelected.delivery_companies.filter(
              (item) =>
                !prevState.some(
                  (dcItem) => item.delivery_company === dcItem.delivery_company
                )
            )
          : partnerSelected.delivery_companies
      )
    );

    filterEntities();
    filterBrands();
    filterDeliveryCompanies();
  }, [brandsFull, partnerSelected, stripe]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <PartnerForm
            {...props}
            entities={entities}
            brands={brands}
            deliveryCompanies={deliveryCompanies}
            handleChange={handleChange}
            partnerSelected={partnerSelected}
            stripe={stripe}
          />
        );
      case 1:
        return (
          <PartnerFormConfigurations
            {...props}
            deliveryCompanies={deliveryCompanies}
            handleChangeConfiguration={handleChangeConfiguration}
            partnerSelectedConfig={partnerSelectedConfig}
            equation={equation}
            structure={structure}
            handleSuppressDateToChange={handleSuppressDateToChange}
            handleSuppressDateFromChange={handleSuppressDateFromChange}
          />
        );
      case 2:
        return (
          <PartnerExtraFees
            {...props}
            feesStructure={feesStructure}
            partnerSelectedConfig={partnerSelectedConfig}
            handleChangeExtraConfiguration={handleChangeExtraConfiguration}
            partnerSelectedExtraConfig={partnerSelectedExtraConfig}
            partnerExtraFee={partnerExtraFee}
            handleChangeExtraFee={handleChangeExtraFee}
            handleExtraFeeAddChange={handleExtraFeeAddChange}
          />
        );
      case 3:
        return (
          <PartnerSubscriptions
            {...props}
            handleChangeSubscription={handleChangeSubscription}
            handleChangeSubscriptionDelete={handleChangeSubscriptionDelete}
            partnerSelectedSubscription={partnerSelectedSubscription}
            partnerExtraSubscription={partnerExtraSubscription}
            handleChangeExtraSubscription={handleChangeExtraSubscription}
            handleChangeExtraSubscriptionAdd={handleChangeExtraSubscriptionAdd}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const handleCancel = () => {
    props.history.push("/partners");
  };

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      toggleConfirmModal();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      Are you sure you want to {!isNaN(partnerParamId) ? " update " : " add "}
      the partner <b>{partnerSelected.name}</b>
      <div align="right">
        <Button onClick={() => toggleConfirmModal()} className={classes.button}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => sendRequest()}
          className={classes.button}
        >
          Confirm
        </Button>
      </div>
    </div>
  );

  const toggleResponseModal = () => setResponseModal(!responseModal);

  const responseModalContent = (
    <div className={classes.modal}>
      {errorInfo.error ? (
        <>
          There was an error
          {!isNaN(partnerParamId) ? " updating " : " adding "}
          the partner <b>{partnerSelected.name}</b>
          <br />
          <br />
          <span
            style={{
              color: "red",
              wordBreak: "break-word",
              maxHeight: 160,
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            <b>Error:</b>
            <br />
            {errorInfo.message}
          </span>
        </>
      ) : (
        <>
          Partner <b>{partnerSelected.name}</b> has been successfully
          {!isNaN(partnerParamId) ? " updated " : " added "}
        </>
      )}
      <div align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={() => resolveRequest()}
          className={classes.button}
        >
          Continue
        </Button>
      </div>
    </div>
  );

  const resolveRequest = () => {
    props.history.push("/partners");
  };

  const sendPartnerStripeInvitation = async () => {
    if (partnerSelected.invite_stripe) {
      backend
        .post(`${API_URL + "data/api/stripeaddpartner/"}`, null, {
          params: {
            partner_id: partnerSelected.raas_location_id,
          },
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        });
    }
    if (partnerSelected.invite_stripe_customer_id) {
      backend
        .post(`${API_URL + "data/api/stripeaddcustomer/"}`, null, {
          params: {
            partner_id: partnerSelected.raas_location_id,
          },
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        });
    }
  };

  const sendPartnerCompanyStructure = async () => {
    partnerSelected.delivery_companies.forEach((item, index) => {
      let structureObj = {};
      switch (partnerSelectedConfig[index].structure_type) {
        case "suppress":
          structureObj = {
            structure_type: partnerSelectedConfig[index].structure_type,
            start_date: partnerSelectedConfig[index].start_date.split("T")[0],
            end_date: partnerSelectedConfig[index].end_date.split("T")[0],
          };
          break;
        case "ceiling":
        case "active_ceiling":
          structureObj = {
            structure_type: partnerSelectedConfig[index].structure_type,
            base_percentage: partnerSelectedConfig[index].base_percentage,
            floor_percentage: partnerSelectedConfig[index].floor_percentage,
          };
          break;
        case "gross":
          structureObj = {
            structure_type: partnerSelectedConfig[index].structure_type,
            base_percentage: partnerSelectedConfig[index].base_percentage,
          };
          break;
        default:
          console.log("error", structureObj);
          break;
      }
      /* if (item.pk) { // this is just in case we have to edit each company instead of saving a new one (post)
        structureObj = { ...structureObj, pk: item.pk };
        backend
          .put(
            `${API_URL}data/api/deliverycompanyconfigurations/${item.pk}`,
            structureObj
          )
          .then((response) => {
            const structure = response.data;
            sendPartnerCompanyConfiguration(structure);
          })
          .catch((error) => {
            console.log(error);
            throw new Error("Error" + JSON.stringify(error));
          });
      } else { */
      backend
        .post(`${API_URL + "data/api/structures/"}`, [structureObj])
        .then((response) => {
          const structure = response.data[0].pk;
          sendPartnerCompanyConfiguration(
            structure,
            partnerSelectedConfig[index]
          );
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        });
      /*  } */
    });
  };

  const sendPartnerCompanyConfiguration = async (structure, item) => {
    let confObj = {
      partner: partnerSelected.raas_location_id,
      delivery_company: item.delivery_company,
      dcid: item.dcid,
      store_id: item.store_id,
      equation: item.equation,
      structure: structure,
    };
    if (item.pk) {
      confObj = { ...confObj, pk: item.pk, fees: item.fees };
      backend
        .put(
          `${API_URL}data/api/deliverycompanyconfigurations/${item.pk}`,
          confObj
        )
        .then((response) => {
          sendPartnerExtraConfiguration(confObj);
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        });
    } else {
      backend
        .post(`${API_URL + "data/api/deliverycompanyconfigurations/"}`, [
          confObj,
        ])
        .then((response) => {
          confObj = { ...confObj, pk: response.data[0].pk };
          sendPartnerExtraConfiguration(confObj);
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        });
    }
  };

  const sendPartnerExtraConfiguration = async (config) => {
    let fees = partnerSelectedExtraConfig
      .filter((item) => item.delivery_company === config.delivery_company)
      .map((item) => ({
        ...item,
        start_date: item.start_date.split("T")[0],
        end_date: item.end_date.split("T")[0],
        delivery_company_configuration: config.pk,
      }));

    let feesData = [];
    Promise.all(
      fees.map(async (feeObj) => {
        if (feeObj.hasOwnProperty("pk")) {
          await backend
            .put(`${API_URL}data/api/fees/${feeObj.pk}`, feeObj)
            .then((response) => {
              feesData.push(response.data);
            })
            .catch((error) => {
              console.log(error);
              setErrorInfo({
                error: true,
                message: JSON.stringify(error.response.data),
              });
              toggleResponseModal();
            });
        } else {
          await backend
            .post(`${API_URL + "data/api/fees/"}`, [feeObj])
            .then((response) => {
              feesData.push(response.data);
            })
            .catch((error) => {
              console.log(error);
              setErrorInfo({
                error: true,
                message: JSON.stringify(error.response.data),
              });
              toggleResponseModal();
            });
        }
      })
    )
      .then((response) => {
        config = { ...config, fees: feesData };
        sendPartnerCompanyConfigurationFeeUpdate(config);
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
        toggleResponseModal();
      });
  };

  const sendPartnerCompanyConfigurationFeeUpdate = async (item) => {
    backend
      .put(`${API_URL}data/api/deliverycompanyconfigurations/${item.pk}`, item)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
        toggleResponseModal();
      });
  };

  const sendPartnerSubscriptions = () => {
    partnerSelectedSubscription.forEach(async (subscription) => {
      if (subscription.pk) {
        let subscriptionObj = subscriptions.find(
          (item) => item.pk === subscription.pk
        );
        if (
          subscriptionObj.description !== subscription.description ||
          subscriptionObj.flat_amount !== subscription.flat_amount
        ) {
          await backend
            .put(
              `${API_URL}data/api/subscriptions/${subscription.pk}`,
              subscription
            )
            .then((response) => {})
            .catch((error) => {
              console.log(error);
              setErrorInfo({
                error: true,
                message: JSON.stringify(error.response.data),
              });
              toggleResponseModal();
            });
        }
      } else {
        await backend
          .post(`${API_URL + "data/api/subscriptions/"}`, [subscription])
          .then((response) => {})
          .catch((error) => {
            console.log(error);
            setErrorInfo({
              error: true,
              message: JSON.stringify(error.response.data),
            });
            toggleResponseModal();
          });
      }
    });
  };

  const sendRequest = async () => {
    const partnerObj = {
      pk: partnerSelected.raas_location_id,
      raas_location_id: partnerSelected.raas_location_id,
      name: partnerSelected.name,
      external_name: partnerSelected.external_name,
      address: partnerSelected.address,
      primary_email: partnerSelected.primary_email,
      delivery_companies: partnerSelected.delivery_companies.map(
        (item) => item.delivery_company
      ),
      entities: partnerSelected.entities.map((item) => item.pk),
      brands: partnerSelected.brands.map((item) => item.pk),
      stripe: partnerSelected.stripe,
      stripe_customer_id: partnerSelected.stripe_customer_id,
    };
    if (!isNaN(partnerParamId)) {
      await backend
        .put(`${API_URL}data/api/partners/${partnerParamId}`, partnerObj)
        .then((response) => {
          sendPartnerStripeInvitation();
          sendPartnerCompanyStructure();
          sendPartnerSubscriptions();
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        })
        .finally(() => {
          if (!errorInfo.error) {
            toggleResponseModal();
          }
        });
    } else {
      await backend
        .post(`${API_URL + "data/api/partners/"}`, [partnerObj])
        .then((response) => {
          sendPartnerStripeInvitation();
          sendPartnerCompanyStructure();
          sendPartnerSubscriptions();
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        })
        .finally(() => {
          if (!errorInfo.error) {
            toggleResponseModal();
          }
        });
    }
  };

  return (
    <div>
      <>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Partner
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <>
              <Modal open={confirmModal} onClose={toggleConfirmModal}>
                {confirmModalContent}
              </Modal>
              <Modal open={responseModal} onClose={toggleResponseModal}>
                {responseModalContent}
              </Modal>
              {getStepContent(activeStep)}
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  onClick={handleCancel}
                  className={classes.button}
                >
                  Cancel
                </Button>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} className={classes.button}>
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? "Save" : "Next"}
                </Button>
              </div>
            </>
          </Paper>
        </main>
      </>
    </div>
  );
};
