import React, { Fragment } from "react";
import {
  MenuItem,
  Select,
  Typography,
  Grid,
  TextField,
  Input,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const BrandMapping = (props) => {
  const classes = useStyles();
  const { brand, accountsNumbers, handleMappingChange, accountsHeaders } =
    props;

  return (
    <div>
      <Typography color="primary" component="h4" style={{marginBottom: 10}}>
        Match the headers with an account number
      </Typography>
      <div className={classes.paper}>
        <form className={classes.form} margin="dense" autoComplete="off">
          <Grid container spacing={1} alignItems="center">
            {accountsHeaders.map((item, index) => (
              <Fragment key={index + "_headers"}>
                <Grid key={index + "_text_container"} item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    key={index + "_text"}
                    size="small"
                    disabled={true}
                    id="outlined-basic"
                    label="Header"
                    variant="outlined"
                    name="accounts"
                    value={item && item}
                  />
                </Grid>
                <Grid key={index + "select_container"} item xs={12} sm={6}>
                  <Select
                    fullWidth
                    key={index + "select"}
                    name={"select-" + item}
                    input={<Input />}
                    value={(brand && brand.accounts[item]) || ""}
                    onChange={handleMappingChange}
                  >
                    <MenuItem value="">
                      <em>- None -</em>
                    </MenuItem>
                    {accountsNumbers.map((item, index) => (
                      <MenuItem
                        key={index + "_" + item.gl_record}
                        value={item.gl_record}
                      >
                        {item.gl_account + " (" + item.gl_name + ")"}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </form>
      </div>
    </div>
  );
};
