import React, { Fragment } from "react";
import {
  MenuItem,
  Select,
  Typography,
  Grid,
  TextField,
  Button,
  Input,
} from "@mui/material";

export const DeliveryMapping = (props) => {
  const {
    companySelected,
    handleExtraMappingChange,
    handleMappingAddChange,
    handleMappingRemoveChange,
    handleMappingChange,
    headerExtraMapping,
    headerMapping,
    orderHeadersSelected,
  } = props;

  return (
    <div>
      <Typography color="primary" component="h4" style={{marginBottom: 10}}>
        Match the headers
      </Typography>
      <div>
        <form autoComplete="off">
          <Grid container spacing={1} alignItems="center">
            {companySelected.headers.map((item, index) => (
              <Fragment key={index + "_headers"}>
                <Grid key={index + "_text_container"} item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    key={index + "_text"}
                    size="small"
                    disabled={true}
                    id="outlined-basic"
                    label="Header"
                    variant="outlined"
                    name="csv_headers"
                    value={item && item}
                  />
                </Grid>
                <Grid key={index + "_select_container"} item xs={12} sm={5}>
                  <Select
                    fullWidth
                    key={index + "_select"}
                    name={"select-" + index}
                    input={<Input />}
                    value={(headerMapping && headerMapping[item]) || ""}
                    onChange={handleMappingChange}
                  >
                    <MenuItem value="">
                      <em>- None -</em>
                    </MenuItem>
                    {orderHeadersSelected
                      .filter((item) => item !== "pk")
                      .map((item) => (
                        <MenuItem key={index + "_" + item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid key={index + "_button_container"} item xs={12} sm={1}>
                  <Button
                    fullWidth
                    key={index + "_button"}
                    name={"remove-" + index}
                    style={{ minWidth: "auto", padding: "6px 10px" }}
                    variant="contained"
                    color="primary"
                    onClick={handleMappingRemoveChange(index, item)}
                  >
                    -
                  </Button>
                </Grid>
              </Fragment>
            ))}
            <Grid key="extra_text_container" item xs={12} sm={6}>
              <TextField
                fullWidth
                key="extra_text"
                size="small"
                id="outlined-basic"
                label="Header"
                variant="outlined"
                name="extra_text"
                value={headerExtraMapping && headerExtraMapping.extra_text}
                onChange={handleExtraMappingChange}
              />
            </Grid>
            <Grid key={"extra_select_container"} item xs={12} sm={5}>
              <Select
                fullWidth
                key="extra_select"
                name="extra_select"
                input={<Input />}
                value={headerExtraMapping && headerExtraMapping.extra_select}
                onChange={handleExtraMappingChange}
              >
                <MenuItem value="">
                  <em>- None -</em>
                </MenuItem>
                {orderHeadersSelected
                  .filter((item) => item !== "pk")
                  .map((item) => (
                    <MenuItem key={"extra_" + item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid key={"extra_button_container"} item xs={12} sm={1}>
              <Button
                fullWidth
                key="extra_button"
                style={{ minWidth: "auto", padding: "6px 10px" }}
                variant="contained"
                color="primary"
                onClick={handleMappingAddChange}
              >
                +
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};
