import React, { Fragment, useState, useEffect } from "react";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { backend, API_URL } from "../backend_api";
import { BrandForm } from "./BrandForm";
import { BrandMapping } from "./BrandMapping";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const BrandProcess = (props) => {
  const classes = useStyles();
  const steps = ["Add Brand", "Mapping"];
  const brandId = props.match.params.id;
  const entityId = !isNaN(+brandId)
    ? props.location.state.data.entity
    : props.match.params.entityId;

  const [activeStep, setActiveStep] = useState(0);

  const [brand, setBrand] = useState({
    entity: entityId,
    name: "",
    accounts: {},
    ar_only: false,
  });

  const [accountsNumbers, setAccountsNumbers] = useState([]);
  const [accountsHeaders, setAccountsHeaders] = useState([]);
  const [accountsMapCategory, setAccountsMapCategory] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });
  const [accountMap, setAccountMap] = useState({});

  const handleChange = (e) => {
    const { checked, name, value } = e.target;

    setBrand((prevState) => ({
      ...prevState,
      [name]: name === "ar_only" ? checked : value,
    }));
  };

  const handleMappingChange = (e) => {
    const { name, value } = e.target;

    const accountsName = name.replace("select-", "");

    setAccountMap((prevState) => ({
      ...prevState,
      [accountsName]: value,
    }));
  };

  const accountsMapCategoryGet = async () => {
    await backend
      .get(`${API_URL}data/api/accountcategorymaps/`)
      .then(({ data }) => {
        setAccountsMapCategory(data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleChange({
      target: {
        name: "accounts",
        value: accountMap,
      },
    });
  }, [accountMap]);

  useEffect(() => {
    const accountsNumberGet = async () => {
      await backend
        .get(`${API_URL}data/api/accounts`)
        .then((response) => {
          const accountsNumbers = response.data.results
            .filter((item) => !["pk"].includes(item))
            .map((item) => ({
              gl_record: item.gl_record,
              gl_account: item.gl_account,
              gl_name: item.gl_name,
            }));
          setAccountsNumbers(accountsNumbers);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const accountsHeadersGet = async () => {
      await backend
        .get(`${API_URL}data/api/accountingheaders`)
        .then((response) => {
          const accountsHeaders = response.data.filter(
            (item) => !["pk"].includes(item)
          );
          setAccountsHeaders(accountsHeaders);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    accountsNumberGet();
    accountsHeadersGet();
  }, []);

  useEffect(() => {
    const brandsGet = async () => {
      await backend
        .get(`${API_URL}data/api/brands/${brandId}`)
        .then(({ data }) => {
          let brandObj = {
            pk: data.id,
            entity: entityId,
            name: data.name,
            accounts: {},
            ar_only: data.ar_only,
          };
          if (brandId) {
            brandObj = { ...brandObj, pk: data.pk };
          }
          setBrand(brandObj);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (!isNaN(brandId)) {
      brandsGet();
      accountsMapCategoryGet();
    }
  }, [brandId, entityId]);

  useEffect(() => {
    const accountMapMapping = () => {
      let brandAccountsDict = {};
      accountsMapCategory
        .filter((item) => item.brand === +brandId)
        .forEach((item) => {
          if (!brandAccountsDict.hasOwnProperty(item.category)) {
            brandAccountsDict[item.category] = item.account;
          }
        });
      setAccountMap(brandAccountsDict);
    };

    if (accountsMapCategory.length && accountsNumbers.length) {
      accountMapMapping();
    }
  }, [brandId, accountsMapCategory, accountsNumbers]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <BrandForm {...props} handleChange={handleChange} brand={brand} />
        );
      case 1:
        return (
          <BrandMapping
            {...props}
            handleChange={handleChange}
            accountsHeaders={accountsHeaders}
            brand={brand}
            accountsNumbers={accountsNumbers}
            handleMappingChange={handleMappingChange}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const handleCancel = () => {
    props.history.push(`/brands/${entityId}`);
  };

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      toggleConfirmModal();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      Are you sure you want to {!isNaN(brandId) ? " update " : " add "}
      the brand <b>{brand.name}</b>
      <div align="right">
        <Button onClick={() => toggleConfirmModal()} className={classes.button}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => sendRequest()}
          className={classes.button}
        >
          Confirm
        </Button>
      </div>
    </div>
  );

  const toggleResponseModal = () => setResponseModal(!responseModal);

  const responseModalContent = (
    <div className={classes.modal}>
      {errorInfo.error ? (
        <>
          There was an error
          {!isNaN(brandId) ? " updating " : " adding "}
          the brand <b>{brand.name}</b>
          <br />
          <br />
          <span
            style={{
              color: "red",
              wordBreak: "break-word",
              maxHeight: 160,
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            <b>Error:</b>
            <br />
            {errorInfo.message}
          </span>
        </>
      ) : (
        <>
          Brand <b>{brand.name}</b> has been successfully
          {!isNaN(brandId) ? " updated " : " added "}
        </>
      )}
      <div align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={() => resolveRequest()}
          className={classes.button}
        >
          Continue
        </Button>
      </div>
    </div>
  );

  const resolveRequest = () => {
    props.history.push("/brands/" + entityId);
  };

  let responses = [];

  const sendRequest = async () => {
    const brandObj = {
      entity: brand.entity,
      name: brand.name,
      ar_only: brand.ar_only,
    };
    if (brandId && !isNaN(brandId)) {
      accountsMapAdd(brandId);
    } else {
      let brandId = 0;
      await backend
        .post(`${API_URL + "data/api/brands/"}`, [brandObj])
        .then((response) => {
          brandId = response.data[0].pk;
          accountsMapAdd(brandId);
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        });
    }

    await backend
      .put(`${API_URL}data/api/brands/${brandId}`, brandObj)
      .then((response) => {
        accountsMapCategoryGet();
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
        toggleResponseModal();
      })
      .finally(() => {
        toggleResponseModal();
      });
  };

  const accountsMapAdd = async (brandId) => {
    const mapHeaders = Object.keys(brand.accounts);
    const accountHeaders = mapHeaders.map((item) => {
      return {
        account: brand.accounts[item],
        brand: brandId,
        category: item,
      };
    });
    accountHeaders.forEach(async (item) => {
      const amc = accountsMapCategory
        .filter((amcItem) => amcItem.brand === +brandId)
        .find((amcItem) => amcItem.category === item.category);

      if (amc) {
        await backend
          .put(`${API_URL}data/api/accountcategorymaps/${amc.pk}`, item)
          .then((response) => {
            responses.push(response.data[0]);
          })
          .catch((error) => {
            console.log(error);
            setErrorInfo({
              error: true,
              message: JSON.stringify(error.response.data),
            });
            toggleResponseModal();
          });
      } else {
        await backend
          .post(`${API_URL}data/api/accountcategorymaps/`, [item])
          .then((response) => {
            responses.push(response.data[0]);
          })
          .catch((error) => {
            console.log(error);
            setErrorInfo({
              error: true,
              message: JSON.stringify(error.response.data),
            });
            toggleResponseModal();
          });
      }
    });
  };

  return (
    <div>
      <Fragment>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Brands
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Modal open={confirmModal} onClose={toggleConfirmModal}>
              {confirmModalContent}
            </Modal>
            <Modal open={responseModal} onClose={toggleResponseModal}>
              {responseModalContent}
            </Modal>
            <Fragment>
              {activeStep === steps.length ? (
                <Fragment></Fragment>
              ) : (
                <Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </Paper>
        </main>
      </Fragment>
    </div>
  );
};
