import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  TextField,
  CssBaseline,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { backend, API_URL } from "../backend_api";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

export const OneOffForm = (props) => {
  const classes = useStyles();

  const [oneOffPayout, setOneOffPayout] = useState({
    pk: "",
    payout_date: "",
    status: "",
    raas_fees: "",
    partner: "",
  });

  useEffect(() => {
    const oneOffPayoutGet = async (props) => {
      await backend
        .get(`${API_URL}data/api/nonorderpayouts/${props.match.params.id}`)
        .then(({ data }) => {
          setOneOffPayout({
            pk: data.pk || "",
            payout_date: data.payout_date || "",
            status: data.status || "",
            raas_fees: data.raas_fees || "",
            partner: data.partner || "",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (props.match.params.id) {
      oneOffPayoutGet(props);
    }
  }, [props]);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography color="primary" component="h4">
            Orders Details
          </Typography>
          <form
            className={classes.form}
            margin="dense"
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Pk: "
                  name="pk"
                  value={oneOffPayout && oneOffPayout.pk}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Payout date: "
                  name="payout_date"
                  value={oneOffPayout && oneOffPayout.payout_date}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Status: "
                  name="status"
                  value={oneOffPayout && oneOffPayout.status}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Raas Fees: "
                  name="raas_fees"
                  value={oneOffPayout && oneOffPayout.raas_fees}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  label="Partner: "
                  name="partner"
                  value={oneOffPayout && oneOffPayout.partner}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
};
