import React, { Fragment } from "react";
import {
  MenuItem,
  Select,
  Typography,
  Grid,
  TextField,
  Input,
  Chip,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const getStyles = (name, orderHeadersSelected, theme) => {
  return {
    fontWeight: orderHeadersSelected
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const EquationSetup = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { handleChange, equationHeaders, equationData, equationOrderData } =
    props;
  const equationId = props.match.params.id;

  return (
    <div>
      <Typography color="primary" component="h4" style={{marginBottom: 10}}>
        Match the headers
      </Typography>
      <div>
        <form className={classes.form} margin="dense" autoComplete="off">
          <Grid container spacing={1} alignItems="center">
            {equationHeaders
              .filter(
                (item) =>
                  item !== "pk" && item !== "structure" && item !== "name"
              )
              .map((item, index) => (
                <Fragment key={index + "_headers"}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      disabled={true}
                      id="outlined-basic"
                      label="Header"
                      variant="outlined"
                      name="csv_headers"
                      value={item && item}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      fullWidth
                      disabled={!isNaN(equationId)}
                      id="outlined-select-currency"
                      multiple
                      label="Delivery Company"
                      onChange={handleChange}
                      name={item}
                      input={<Input />}
                      value={equationData && equationData[item]}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((item) => (
                            <Chip
                              key={item}
                              label={item}
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                      helpertext="If the company it is not in the list add a new one."
                    >
                      {equationOrderData[item].map((option) => (
                        <MenuItem
                          key={option}
                          value={option}
                          style={getStyles(option, equationData[item], theme)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Fragment>
              ))}
          </Grid>
        </form>
      </div>
    </div>
  );
};
