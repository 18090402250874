import React, { useState } from "react";
import InfiniteTable from "./InfiniteTable";
import {
  Button,
  Container,
  Modal,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { backend, API_URL } from "../backend_api";

const columns = [
  {
    id: "stripe_account_id",
    editable: "/stripe",
    editablePk: "stripe_account_id",
    label: "Stripe Account",
    title: "partners",
  },
  {
    id: "valid",
    label: "Valid",
  },
  {
    id: "bank_account",
    label: "Bank Account",
  },
  {
    id: "charges_enabled",
    label: "Charges Enabled",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "payouts_enabled",
    label: "Payout Enabled",
  },
  {
    id: "disabled_reason",
    label: "Disabled Reason",
  },
];

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const Stripe = (props) => {
  const classes = useStyles();
  const [confirmModal, setConfirmModal] = useState(false);
  const toggleConfirmModal = () => setConfirmModal(!confirmModal);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [finishedFlag, setFinishedFlag] = useState(false);

  const confirmModalContent = (
    <div className={classes.modal}>
      {!loadingFlag && !finishedFlag ? (
        <>
          Are you sure you want to refresh <b>stripe information</b>?
          <div align="right">
            <Button
              onClick={() => toggleConfirmModal()}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => sendRequest()}
              className={classes.button}
            >
              Confirm
            </Button>
          </div>
        </>
      ) : loadingFlag ? (
        <>
          <div align="center">
            <CircularProgress />
            <div>Refreshing...</div>
          </div>
          {errorInfo.error ? (
              <>
                <br />
                <br />
                There was an error while refreshing stripe
                <br />
                <br />
                <span
                  style={{
                    color: "red",
                    wordBreak: "break-word",
                    maxHeight: 160,
                    overflow: "hidden",
                    display: "inline-block",
                  }}
                >
                  <b>Error:</b>
                  <br />
                  {errorInfo.message}
                </span>
                <div align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => resolveRequest()}
                    className={classes.button}
                  >
                    Continue
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
        </>
      ) : (
        <>
          Stripe information has been refreshed successfully
          <div align="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => resolveRequest()}
              className={classes.button}
            >
              Close
            </Button>
          </div>
        </>
      )}
    </div>
  );

  const resolveRequest = () => {
    toggleConfirmModal();
    setFinishedFlag(false);
    props.history.push("/stripe");
  };

  const sendRequest = async () => {
    setLoadingFlag(true);
    await backend
      .get(`${API_URL + "data/api/striperefresh/"}`)
      .then((response) => {
        setLoadingFlag(false);
        setFinishedFlag(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorInfo({
          error: true,
          message: JSON.stringify(error.response.data),
        });
      });
  };

  return (
    <>
      <Container style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleConfirmModal}
        >
          Refresh stripe
        </Button>
      </Container>
      <Modal open={confirmModal} onClose={toggleConfirmModal}>
        {confirmModalContent}
      </Modal>
      <Container>
        <InfiniteTable
          apiep="data/api/stripesetups/"
          columns={columns}
          data={null}
          dataMapByColumn="id"
          deletable={false}
          editable={true}
          filterable={true}
          label="Stripe"
          fetchingModal={null}
        />
      </Container>
    </>
  );
};
