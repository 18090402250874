import React from "react";
import InfiniteTable from "./InfiniteTable";
import { Link } from "react-router-dom";
import { Container, Button, Typography } from "@mui/material";

const columns = [
  { id: "oid", editable: "/order", editablePk: "pk", label: "Order ID" },
  {
    id: "order_date",
    label: "Order date",
  },
  {
    id: "partner_name",
    editable: "/partners",
    editablePk: "partner",
    label: "Partner",
  },
  { id: "sales", label: "Sales" },
  {
    id: "stated_payout",
    label: "Stated payout",
  },
  {
    id: "stated_payout_date",
    label: "Stated payout date",
  },
  {
    id: "delivery_company_name",
    editable: "/deliverycompanies",
    editablePk: "delivery_company",
    label: "Delivery company",
  },
  {
    id: "brand_name",
    editable: "/brands",
    editablePk: "brand",
    label: "Brand",
  },
  {
    id: "entity_name",
    editable: "/entities",
    editablePk: "entity",
    label: "Entity",
  },
];

export const Orders = () => {
  return (
    <>
      <Container style={{ textAlign: "right" }}>
        <Link
          to="/payout/sync"
          style={{ marginRight: "10px", textDecoration: "none" }}
          color="secondary"
        >
          <Button variant="contained" color="primary">
            <Typography variant="subtitle2" className="text-white">
              Sync Orders
            </Typography>
          </Button>
        </Link>
        <Link
          to="/orders/add"
          style={{ textDecoration: "none" }}
          color="secondary"
        >
          <Button variant="contained" color="primary">
            <Typography variant="subtitle2" className="text-white">
              Add Orders
            </Typography>
          </Button>
        </Link>
      </Container>
      <Container>
        <InfiniteTable
          apiep="data/api/orders/"
          columns={columns}
          data={null}
          dataMapByColumn="id"
          deletable={false}
          editable={true}
          filterable={true}
          label="Orders"
          fetchingModal={null}
        />
      </Container>
    </>
  );
};
