import React, { Fragment } from "react";

import {
  Typography,
  Container,
  TextField,
  CssBaseline,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Input
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

export const PartnerFormConfigurations = (props) => {
  const classes = useStyles();
  const {
    handleChangeConfiguration,
    partnerSelectedConfig,
    equation,
    structure,
    handleSuppressDateToChange,
    handleSuppressDateFromChange,
  } = props;

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <form className={classes.form} margin="dense" autoComplete="off">
            {partnerSelectedConfig.map((item, idx) => (
              <Fragment key={idx + "_container"}>
                <Typography
                  color="primary"
                  variant="h6"
                  style={{
                    textAlign: "center",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  {item.delivery_company_name}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      variant="outlined"
                      label="UUID"
                      name="dcid"
                      value={(item.dcid && item.dcid) || ""}
                      onChange={handleChangeConfiguration(idx)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      required={true}
                      variant="outlined"
                      label="Store ID: "
                      name="store_id"
                      value={item.store_id && item.store_id}
                      onChange={handleChangeConfiguration(idx)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputLabel shrink id="equationLabel">
                      Equation
                    </InputLabel>
                    <Select
                      fullWidth
                      className={classes.formControl}
                      key="equation"
                      name="equation"
                      label="Equation"
                      input={<Input />}
                      value={(item.equation && item.equation) || ""}
                      onChange={handleChangeConfiguration(idx)}
                    >
                      {equation.map((item) => (
                        <MenuItem key={idx + "_" + item.name} value={item.pk}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputLabel shrink id="structureLabel">
                      Structure
                    </InputLabel>
                    <Select
                      fullWidth
                      className={classes.formControl}
                      key="structur_type"
                      name="structure_type"
                      label="Structure_type"
                      input={<Input />}
                      value={(item.structure_type && item.structure_type) || ""}
                      onChange={handleChangeConfiguration(idx)}
                    >
                      {structure.map((item) => (
                        <MenuItem key={idx + "_" + item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  {item.structure_type === "suppress" ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            value={item.start_date && item.start_date}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                fullWidth
                                label="Select start date"
                                variant="standard"
                              />
                            )}
                            onChange={handleSuppressDateFromChange(idx)}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            value={item.end_date && item.end_date}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                fullWidth
                                label="Select end date"
                                variant="standard"
                              />
                            )}
                            onChange={handleSuppressDateToChange(idx)}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {item.structure_type && item.structure_type !== "supress" ? (
                    <Grid
                      item
                      xs={item.structure_type === "supress" ? 12 : 6}
                      sm={item.structure_type === "supress" ? 12 : 6}
                    >
                      <TextField
                        size="small"
                        fullWidth
                        required
                        variant="outlined"
                        label="Base percentage: "
                        type="number"
                        name="base_percentage"
                        value={item.base_percentage && item.base_percentage}
                        onChange={handleChangeConfiguration(idx)}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {item.structure_type === "ceiling" ||
                  item.structure_type === "active_ceiling" ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          size="small"
                          fullWidth
                          required
                          variant="outlined"
                          label="Floor percentage: "
                          type="number"
                          name="floor_percentage"
                          value={item.floor_percentage && item.floor_percentage}
                          onChange={handleChangeConfiguration(idx)}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Fragment>
            ))}
          </form>
        </div>
      </Container>
    </>
  );
};

export default PartnerFormConfigurations;
