import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  CssBaseline,
  Paper,
  Button,
  Typography,
  Modal,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { backend, API_URL } from "../backend_api";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 385,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: ".3rem",
    boxShadow: theme.shadows[5],
    padding: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const DeliverySettings = (props) => {
  const classes = useStyles();
  const companyId = props.match.params.id;

  const [companySelected, setCompanySelected] = useState({
    name: "",
    csv_header_mapping: {},
    default_identifier: "store_id",
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [errorInfo, setErrorInfo] = useState({ error: false, message: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCompanySelected((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    props.history.push("/deliverycompany");
  };

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const confirmModalContent = (
    <div className={classes.modal}>
      Are you sure you want to {!isNaN(companyId) ? " update " : " add "}
      the delivery company <b>{companySelected.name}</b>
      <div align="right">
        <Button onClick={() => toggleConfirmModal()} className={classes.button}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => sendRequest()}
          className={classes.button}
        >
          Confirm
        </Button>
      </div>
    </div>
  );

  const toggleResponseModal = () => setResponseModal(!responseModal);

  const responseModalContent = (
    <div className={classes.modal}>
      {errorInfo.error ? (
        <>
          There was an error
          {!isNaN(companyId) ? " updating " : " adding "}
          the delivery company <b>{companySelected.name}</b>
          <br />
          <br />
          <span
            style={{
              color: "red",
              wordBreak: "break-word",
              maxHeight: 160,
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            <b>Error:</b>
            <br />
            {errorInfo.message}
          </span>
        </>
      ) : (
        <>
          Delivery company <b>{companySelected.name}</b> has been successfully
          {!isNaN(companyId) ? " updated " : " added "}
        </>
      )}
      <div align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={() => resolveRequest()}
          className={classes.button}
        >
          Continue
        </Button>
      </div>
    </div>
  );

  const resolveRequest = () => {
    props.history.push(`/deliverycompanies/${props.match.params.id}`);
  };

  const sendRequest = async () => {
    const companyObj = {
      csv_header_mapping: JSON.stringify(companySelected.csv_header_mapping),
      default_identifier: companySelected.default_identifier,
      name: companySelected.name,
    };

    if (companyId && !isNaN(companyId)) {
      await backend
        .put(
          `${API_URL}data/api/deliverycompanies/${props.match.params.id}`,
          companyObj
        )
        .then((response) => {
          toggleResponseModal();
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        });
    } else {
      await backend
        .post(`${API_URL + "data/api/deliverycompanies/"}`, [companyObj])
        .then((response) => {
          toggleResponseModal();
        })
        .catch((error) => {
          console.log(error);
          setErrorInfo({
            error: true,
            message: JSON.stringify(error.response.data),
          });
          toggleResponseModal();
        });
    }
  };

  useEffect(() => {
    const deliveryCompanyGet = async () => {
      await backend
        .get(`${API_URL}data/api/deliverycompanies/${companyId}`)
        .then((response) => {
          let companyObj = {
            pk: response.data.id,
            name: response.data.name,
            csv_header_mapping: response.data.csv_header_mapping,
            default_identifier: response.data.default_identifier || "store_id",
          };
          if (companyId) {
            companyObj = { ...companyObj, pk: response.data.pk };
          }
          setCompanySelected(companyObj);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (!isNaN(companyId)) {
      deliveryCompanyGet(companyId);
    }
  }, [companyId]);

  return (
    <div>
      <>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Delivery Company Settings
            </Typography>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Modal open={confirmModal} onClose={toggleConfirmModal}>
                {confirmModalContent}
              </Modal>
              <Modal open={responseModal} onClose={toggleResponseModal}>
                {responseModalContent}
              </Modal>
              <>
                <form className={classes.form} noValidate autoComplete="off">
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        disabled={true}
                        size="small"
                        id="outlined-basic"
                        label="Delivery company "
                        name="delivery_company"
                        value={companySelected && companySelected.name}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <InputLabel shrink id="defaultIdentifierLabel">
                        Default identifier
                      </InputLabel>
                      <Select
                        fullWidth
                        key="select"
                        name="default_identifier"
                        label="Default identifier"
                        input={<Input />}
                        value={
                          companySelected && companySelected.default_identifier
                        }
                        onChange={handleChange}
                      >
                        <MenuItem key="store_id" value="store_id">
                          Store
                        </MenuItem>
                        <MenuItem key="uuid" value="uuid">
                          Uuid
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </form>
                <>
                  <div className={classes.buttons}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={toggleConfirmModal}
                      className={classes.button}
                    >
                      Save
                    </Button>
                  </div>
                </>
              </>
            </Container>
          </Paper>
        </main>
      </>
    </div>
  );
};
