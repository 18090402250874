import React from "react";
import InfiniteTable from "./InfiniteTable";
import { Container } from "@mui/material";

const columns = [
  {
    id: "partner",
    editable: "/partners",
    editablePk: "partner",
    label: "Partner",
  },
  {
    id: "base_percentage",
    label: "Base percentage",
  },
  {
    id: "floor_percentage",
    label: "Floor percentage",
  },
  {
    id: "recovered_amount",
    label: "Recovered amount",
  },
  {
    id: "start_date",
    label: "Start date",
  },
  {
    id: "end_date",
    label: "End date",
  },
  {
    id: "valid",
    label: "Valid",
  },
  {
    id: "brand_name",
    label: "Brand",
  },
  {
    id: "delivery_company_name",
    editable: "/deliverycompanies",
    editablePk: "delivery_company",
    label: "Delivery company",
  },
];

export const Fees = (props) => {
  return (
    <>
      <Container>
        <InfiniteTable
          apiep="data/api/fees/"
          columns={columns}
          data={null}
          dataMapByColumn="id"
          deletable={false}
          editable={true}
          filterable={true}
          label="Fees"
          fetchingModal={null}
        />
      </Container>
    </>
  );
};
