import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Typography, Container, Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";
import { Link } from "react-router-dom";
import InfiniteTable from "./InfiniteTable";
import { backend, API_URL } from "../backend_api";

const columns = [
  { id: "pk", editable: "/brand", editablePk: "pk", label: "Brand ID" },
  { id: "name", label: "Name" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(2),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const Brands = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState([]);

  const brandId = props.match.params.id;
  const entityId = props.match.params.entityId;

  useEffect(() => {
    const brandsGet = async () => {
      await backend
        .get(`${API_URL}data/api/brands?no_page=1`)
        .then((response) => {
          const data = response.data.filter(
            (item) => item.entity === +entityId
          );
          setData(data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (!isNaN(entityId)) {
      brandsGet();
    }
  }, [entityId]);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const handleButtonClick = () => {
    sendRequest();
    if (!loading) {
      setSuccess(false);
      setLoading(true);
    }
    resolveRequest();
  };

  const resolveRequest = () => {
    setSuccess(false);
  };

  const sendRequest = async () => {
    setLoading(true);
    await backend
      .get(`${API_URL + "data/api/accountsrefresh/"}`)
      .then((response) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Container style={{ textAlign: "right" }}>
        <div className={classes.wrapper}>
          <Button
            style={{ marginRight: "10px" }}
            variant="contained"
            color="primary"
            className={buttonClassname}
            disabled={loading}
            onClick={handleButtonClick}
          >
            <Typography variant="subtitle2" className="text-white">
              Update Accounts{" "}
            </Typography>
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          <Link
            to={
              "/brand/" + entityId + "/" + (!isNaN(brandId) ? brandId : "add")
            }
            style={{ textDecoration: "none" }}
            color="secondary"
          >
            <Button variant="contained" color="primary">
              <Typography variant="subtitle2" className="text-white">
                Add Brand
              </Typography>
            </Button>
          </Link>
        </div>
      </Container>
      <Container>
        <InfiniteTable
          apiep={null}
          columns={columns}
          data={data}
          dataMapByColumn="id"
          deletable={false}
          editable={true}
          filterable={true}
          label="Brands"
          fetchingModal={null}
        />
      </Container>
    </>
  );
};

export default Brands;
