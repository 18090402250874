import React, { Fragment } from "react";
import {
  Container,
  Typography,
  CssBaseline,
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
  Input,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 385,
  },
}));

export const PartnerExtraFees = (props) => {
  const classes = useStyles();
  const {
    feesStructure,
    partnerSelectedConfig,
    handleChangeExtraConfiguration,
    partnerSelectedExtraConfig,
    partnerExtraFee,
    handleChangeExtraFee,
    handleExtraFeeAddChange,
    /* extraDateTo,
    extraDateFrom,
    handleExtraDateToChange,
    handleExtraDateFromChange, */
  } = props;

  return (
    <>
      <Container>
        <CssBaseline />
        <div className={classes.paper}>
          <form
            className={classes.form}
            margin="dense"
            noValidate
            autoComplete="off"
          >
            {partnerSelectedExtraConfig.length === 0 ? (
              <Typography
                color="primary"
                component="h4"
                style={{ textAlign: "center" }}
              >
                <b>There are no extra fees added</b>
              </Typography>
            ) : (
                  <Grid container spacing={2}>
              {partnerSelectedExtraConfig.map((item, index) => (
                <Fragment key={index}>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        color="primary"
                        component="h4"
                        style={{ textAlign: "center" }}
                      >
                        {"Fee " + (index + 1)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        fullWidth
                        key="structure_type"
                        name="structure_type"
                        input={<Input />}
                        value={item.structure_type && item.structure_type}
                        onChange={handleChangeExtraFee}
                      >
                        {feesStructure.map((item) => (
                          <MenuItem
                            key={"structure_type-" + index + "-" + item}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        fullWidth
                        key={"delivery_company-" + index}
                        name={"delivery_company-" + index}
                        input={<Input />}
                        value={item.delivery_company && item.delivery_company}
                        onChange={handleChangeExtraConfiguration(index)}
                      >
                        {partnerSelectedConfig.map((item) => (
                          <MenuItem
                            key={
                              "delivery_company-" +
                              index +
                              "-" +
                              item.delivery_company
                            }
                            value={item.delivery_company}
                          >
                            {item.delivery_company_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        size="small"
                        required={true}
                        id="outlined-basic"
                        variant="outlined"
                        label="Base percentage: "
                        key={"base_percentage-" + index}
                        name={"base_percentage-" + index}
                        value={item.base_percentage && item.base_percentage}
                        onChange={handleChangeExtraConfiguration(index)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        size="small"
                        required={true}
                        id="outlined-basic"
                        variant="outlined"
                        label="Floor percentage: "
                        key={"floor_percentage-" + index}
                        name={"floor_percentage-" + index}
                        value={item.floor_percentage && item.floor_percentage}
                        onChange={handleChangeExtraConfiguration(index)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        size="small"
                        required={true}
                        id="outlined-basic"
                        variant="outlined"
                        label="Recovered amount: "
                        key={"recovered_amount-" + index}
                        name={"recovered_amount-" + index}
                        value={item.recovered_amount && item.recovered_amount}
                        onChange={handleChangeExtraConfiguration(index)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        type="number"
                        size="small"
                        required={true}
                        id="outlined-basic"
                        variant="outlined"
                        label="Flat amount: "
                        key={"flat_amount-" + index}
                        name={"flat_amount-" + index}
                        value={item.flat_amount && item.flat_amount}
                        onChange={handleChangeExtraConfiguration(index)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        value="start"
                        control={
                          <Checkbox
                            name="constant"
                            value={item.constant && item.constant}
                            checked={item.constant && item.constant}
                            onChange={handleChangeExtraConfiguration(index)}
                            color="primary"
                          />
                        }
                        label="Constant"
                        labelPlacement="end"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        value="start"
                        control={
                          <Checkbox
                            name="valid"
                            value={item.valid && item.valid}
                            checked={item.valid && item.valid}
                            onChange={handleChangeExtraConfiguration(index)}
                            color="primary"
                          />
                        }
                        label="Valid"
                        labelPlacement="end"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableToolbar
                          disabled={item.constant}
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          key={"start_date-" + index}
                          value={item.start_date && item.start_date}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              fullWidth
                              label="Select start date"
                              variant="standard"
                            />
                          )}
                          onChange={handleChangeExtraConfiguration(
                            index,
                            "start_date"
                          )}
                          KeyboardButtonProps={{ "aria-label": "change date" }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableToolbar
                          disabled={item.constant}
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          key={"end_date-" + index}
                          value={item.end_date && item.end_date}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              fullWidth
                              label="Select end date"
                              variant="standard"
                            />
                          )}
                          onChange={handleChangeExtraConfiguration(
                            index,
                            "end_date"
                          )}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                </Fragment>
              ))}
                  </Grid>
            )}
            <Grid container spacing={2} style={{ marginTop: 24 }}>
              <Grid
                container
                alignItems={"center"}
                style={{ justifyContent: "center" }}
              >
                <Typography color="primary" component="h4">
                  Add a fee with the following information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  key="structure_type"
                  name="structure_type"
                  input={<Input />}
                  value={partnerExtraFee && partnerExtraFee.structure_type}
                  onChange={handleChangeExtraFee}
                >
                  {feesStructure.map((item) => (
                    <MenuItem key={"structure_type_" + item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  key="delivery_company"
                  name="delivery_company"
                  input={<Input />}
                  value={partnerExtraFee && partnerExtraFee.delivery_company}
                  onChange={handleChangeExtraFee}
                >
                  {partnerSelectedConfig.map((item) => (
                    <MenuItem
                      key={"delivery_company_" + item.delivery_company}
                      value={item.delivery_company}
                    >
                      {item.delivery_company_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Base percentage: "
                  name="base_percentage"
                  value={partnerExtraFee && partnerExtraFee.base_percentage}
                  onChange={handleChangeExtraFee}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Floor percentage: "
                  name="floor_percentage"
                  value={partnerExtraFee && partnerExtraFee.floor_percentage}
                  onChange={handleChangeExtraFee}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Recovered amount: "
                  name="recovered_amount"
                  value={partnerExtraFee && partnerExtraFee.recovered_amount}
                  onChange={handleChangeExtraFee}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  label="Flat amount: "
                  name="flat_amount"
                  value={partnerExtraFee && partnerExtraFee.flat_amount}
                  onChange={handleChangeExtraFee}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      name="constant"
                      value={partnerExtraFee && partnerExtraFee.constant}
                      checked={partnerExtraFee && partnerExtraFee.constant}
                      onChange={handleChangeExtraFee}
                      color="primary"
                    />
                  }
                  label="Constant"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      name="valid"
                      value={partnerExtraFee && partnerExtraFee.valid}
                      checked={partnerExtraFee && partnerExtraFee.valid}
                      onChange={handleChangeExtraFee}
                      color="primary"
                    />
                  }
                  label="Valid"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableToolbar
                    disabled={partnerExtraFee.constant}
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    value={partnerExtraFee && partnerExtraFee.start_date}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        fullWidth
                        label="Select end date"
                        variant="standard"
                      />
                    )}
                    onChange={(date, formatted) =>
                      handleChangeExtraFee({
                        target: {
                          name: "start_date",
                          value: date,
                          checked: false,
                        },
                      })
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableToolbar
                    disabled={partnerExtraFee.constant}
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    value={partnerExtraFee && partnerExtraFee.end_date}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        fullWidth
                        label="Select end date"
                        variant="standard"
                      />
                    )}
                    onChange={(date, formatted) =>
                      handleChangeExtraFee({
                        target: {
                          name: "end_date",
                          value: date,
                          checked: false,
                        },
                      })
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid container style={{ justifyContent: "flex-end" }}>
                <Button
                  style={{
                    minWidth: "auto",
                    marginTop: 10,
                    padding: "6px 10px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleExtraFeeAddChange}
                  disabled={
                    partnerExtraFee.structure_type === "" ||
                    partnerExtraFee.delivery_company === ""
                  }
                >
                  Add fee
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
};

export default PartnerExtraFees;
