import React from "react";
import InfiniteTable from "./InfiniteTable";
import { Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const columns = [
  { id: "pk", editable: "/oneoff", editablePk: "pk", label: "One-Off ID" },
  { id: "payout_date", label: "Payout Date" },
  { id: "status", label: "Status" },
  { id: "raas_fees", label: "Raas fees" },
  { id: "partner", label: "Partner" },
  { id: "entity", label: "Entity" },
];

export const OneOff = () => {
  return (
    <>
      <Container style={{ height: 33 }}>
        <Link to="/payout/add" style={{ textDecoration: "none", float: "left" }} color="secondary">
          <Button variant="contained" color="inherit">
            <Typography variant="subtitle2" className="text-dark">
              Back to Make Payout
            </Typography>
          </Button>
        </Link>
        <Link to="/oneoffs/add" style={{ textDecoration: "none", float: "right" }} color="secondary">
          <Button variant="contained" color="primary">
            <Typography variant="subtitle2" className="text-white">
              Make One-Off Payout
            </Typography>
          </Button>
        </Link>
      </Container>
      <Container style={{clear: "both"}}>
        <InfiniteTable
          apiep="data/api/nonorderpayouts/"
          columns={columns}
          data={null}
          dataMapByColumn="id"
          deletable={false}
          editable={false}
          filterable={true}
          label="One-Off Payouts"
          fetchingModal={null}
        />
      </Container>
    </>
  );
};
